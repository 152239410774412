import axios from "axios";
import { useTranslation } from "react-i18next";

import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { AllActions } from "store/reducers/AllActions";

import { getCountryId, getGoalId } from "utils/helpers";

import animation from "assets/animations/main.riv";
import { mainHeroImageMobile } from "assets/img";
import { scrollToWithOffset } from "../../../utils/helpers/scroll.helper";

import { Proxy } from "./components/Proxy/Proxy";
import { UserChoice } from "./components/UserChoice/UserChoice";

import styles from "./MainPage.module.scss";

const MainPage = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    scrollToWithOffset("proxyContent", -50);
  };

  return (
    <>
      <Main
        highlightWord={t("mainPage.highlightWord")}
        title={t("mainPage.title")}
        subtitle={t("mainPage.description")}
        buttonLabel={t("navigation.selectService")}
        animation={<Rive src={animation} autoPlay />}
        images={{
          mobile1x: mainHeroImageMobile,
          mobile2x: mainHeroImageMobile
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        containerClassName={styles.hero__container}
        handleButtonClick={handleButtonClick}
        star
      />
      <Proxy />
      <UserChoice />
      <SeoSection noPaddingTop />
    </>
  );
};
export default MainPage;

MainPage.getServerSideState = async (store, params, api) => {
  const fptParams = params.params.fpt || "IPv4";
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    {
      ...params,
      location: params.params.fpt
        ? params.location
        : `${params.location}?fpt=IPv4`,
      params: {
        ...params.params,
        fpt: Array.isArray(fptParams)
          ? fptParams[fptParams.length - 1]
          : fptParams || null
      }
    }
  );

  const {
    content: { countries, goals }
  } = store.getState();

  const fetchParams = {
    proxyType: Array.isArray(fptParams) ? fptParams : [fptParams],
    country: getCountryId(countries.data, params?.params?.fc)?.id || "",
    sort: params?.params?.s || "",
    goal: getGoalId(goals.data, params?.params?.fg)?.id || "",
    page: 0,
    size: 10
  };
  const { data: sites } = await axios.post(
    `${api}/api/front/site/proxy`,
    fetchParams
  );

  const { data: UserChoiceData } = await axios.get(
    `${api}/api/front/userChoices`,
    {
      params: {
        size: 10
      }
    }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );
  store.dispatch(AllActions.setProxySites({ ...sites, fetchParams }));
  store.dispatch(AllActions.setUserChoice(UserChoiceData));
};
