import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import IconsSVG from "components/ui/New/Icon/IconsSVG";
import Select from "components/ui/New/Select/Select";

import { useDispatchedActions } from "hooks";

import { getParamsUrlForFilter } from "utils/helpers";

import {
  ReStyledDropdown,
  ReStyledDropdownItem,
  StyledSelectBox
} from "./ProxyFilter.styled";

export const ProxyFilter = memo(() => {
  // **Props
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // **Redux state
  const { t } = useTranslation();

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const routeHandler = (filter) => {
    setActiveFilters({ filter });

    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const fpts = searchParams.getAll("fpt");
    const s = searchParams.get("s");
    navigate(getParamsUrlForFilter(fc, fg, fpts, s, filter));
  };
  const options = [
    { value: "rating", label: t("proxyFilters.rating") },
    { value: "reviews", label: t("proxyFilters.review") },
    { value: "costs", label: t("proxyFilters.costs") }
  ];

  const filter = searchParams.get("filter");
  const activeFilter =
    options.find((item) => item.value === filter)?.value || "rating";

  return (
    <>
      <ReStyledDropdown
        label={<IconsSVG name="filter" />}
        align={"end"}
        direction={"bottom"}
      >
        {options.map((item) => (
          <ReStyledDropdownItem
            key={item.value}
            onClick={() => routeHandler(item.value)}
            active={activeFilter === item.value}
          >
            {item.label}
          </ReStyledDropdownItem>
        ))}
      </ReStyledDropdown>
      <StyledSelectBox>
        <Select
          options={options}
          defaultValue={activeFilter}
          onChange={routeHandler}
        />
      </StyledSelectBox>
    </>
  );
});
