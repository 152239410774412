import styled from "@emotion/styled";

import Button from "../../../ui/New/Button/Button";

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 32px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: auto;
    align-self: flex-start;
  }

  &:hover,
  &:focus {
    box-shadow: ${(p) => p.theme.shadows.buttonShadow};
  }
`;

export const HeroSection = styled.section`
  position: relative;
  width: 100%;
  background-color: ${(p) => p.theme.colors["background-color-black"]};
  overflow: hidden !important;
  box-sizing: border-box;
`;

export const SubTitle = styled.p`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  line-height: 150%;
  text-align: left;
  color: ${(p) => p.theme.colors.white};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 85%;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    font-size: 18px;
    line-height: 167%;
  }

  a {
    text-decoration-line: underline;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 0;
    max-width: 500px;
  }
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    max-width: 640px;
  }
`;

export const StyledSkeletonBoxTitle = styled.div`
  width: 100%;
  margin-bottom: 16px;

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.xxl};

  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: 1.11;
  letter-spacing: -0.54px;
  color: ${(p) => p.theme.colors.white};

  @media (min-width: 1200px) {
    font-size: 72px;
    line-height: 1.08;
    letter-spacing: -2px;
    text-align: left;
  }
`;
