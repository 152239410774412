import { Select } from "components/ui/New/Select/Select";

import { StyledSortBox, StyledSortLabel } from "./SortBlock.styled";

export const SortBlock = ({ value, label, options, changeSortType }) => (
  <StyledSortBox>
    {label ? <StyledSortLabel>{label}</StyledSortLabel> : null}
    <Select value={value} onChange={changeSortType} options={options} />
  </StyledSortBox>
);
