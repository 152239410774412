import { useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Input } from "components/forms/New";
import FormError from "components/forms/New/FormError/FormError";
import Button from "components/ui/New/Button/Button";

import {
  StyledContactBox,
  StyledContactEditButton,
  StyledContactEditorBox,
  StyledContactEditorButtons,
  StyledContactLabel,
  StyledContactValue
} from "./Contact.styled";

export const Contact = ({ name, label }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  // const value = watch(name);
  const value = watch(`socialNetworks.${name}`);
  const { errors } = useFormState();

  const [edit, setEdit] = useState(false);
  const [oldValue, setOldValue] = useState(value || "");

  const handleSave = () => {
    setEdit(false);
  };

  const handleCancel = () => {
    setValue(`socialNetworks.${name}`, oldValue);
    setEdit(false);
  };

  const handleEdit = () => {
    setOldValue(value);
    setEdit(true);
  };

  return (
    <>
      <StyledContactBox>
        <StyledContactLabel>{label}</StyledContactLabel>
        {!edit && (
          <>
            {value && <StyledContactValue>{value}</StyledContactValue>}
            <StyledContactEditButton
              variant={"secondary"}
              iconLeft={value ? "editV2" : "plus"}
              onClick={handleEdit}
            >
              {value ? t("forms.buttons.edit") : t("forms.buttons.add")}
            </StyledContactEditButton>
          </>
        )}
        <StyledContactEditorBox hidden={!edit}>
          <Input name={`socialNetworks.${name}`} showError />
          <StyledContactEditorButtons>
            <Button onClick={handleSave}>{t("forms.buttons.save")}</Button>
            <Button variant={"secondary"} onClick={handleCancel}>
              {t("forms.buttons.cancel")}
            </Button>
          </StyledContactEditorButtons>
        </StyledContactEditorBox>
      </StyledContactBox>
      {!edit && errors?.socialNetworks?.[name]?.message && (
        <FormError message={errors?.socialNetworks?.[name]?.message} />
      )}
    </>
  );
};
