// import Card from "components/ui/New/Card/Card";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  BoxCell,
  CountryNameCell,
  HeaderNameCell,
  IndexCell,
  NoDataComponent,
  TextCell
} from "components/common/New/TableColumns";
import { SkeletonUserChoise } from "components/ui/New/Skeleton/SkeletonUserChoise/SkeletonUserChoise";
import TextButton from "components/ui/New/TextButton/TextButton";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useDispatchedActions, useLangUrlDefault } from "hooks";

import {
  StyledUserChoiceSpase,
  StyledUserChoiceTable,
  StyledUserChoiceTitle
} from "./UserChoiceTable.styled";

export const UserChoiceTable = () => {
  const { t } = useTranslation();
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { userChoice } = useSelector(getAllContent);

  const { getUserChoice } = useDispatchedActions();

  const columns = [
    {
      name: (
        <HeaderNameCell minWidth={50} center>
          №
        </HeaderNameCell>
      ),
      cell: (row, index) => {
        const currentNumber = index + 1;
        return (
          <BoxCell width={50}>
            <IndexCell>
              {`${currentNumber < 10 ? "0" : ""}${currentNumber}.`}
            </IndexCell>
          </BoxCell>
        );
      },
      width: "50px",
      center: true
    },
    {
      name: (
        <HeaderNameCell width={175}>
          {t("mainPage.userChoice.columns.name")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={175}>
          <CountryNameCell
            name={row?.countryLocalization?.[queryLang]}
            code={row?.countryCode}
          />
        </BoxCell>
      ),
      width: "175px"
    },
    // {
    //   cell: (row) => <NewCell isNew={Boolean(row?.new)} />,
    //   minWidth: "75px"
    // },
    {
      name: (
        <HeaderNameCell minWidth={95}>
          {t("mainPage.userChoice.columns.price")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell minWidth={95}>
          <TextCell>{`${row?.price} USD`}</TextCell>
        </BoxCell>
      ),
      minWidth: "95px"
    },
    {
      name: (
        <HeaderNameCell minWidth={90}>
          {t("mainPage.userChoice.columns.offers")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell minWidth={90}>
          <TextCell>{row?.offers}</TextCell>
        </BoxCell>
      ),
      minWidth: "90px"
    },
    {
      name: (
        <HeaderNameCell minWidth={80}>
          {t("mainPage.userChoice.columns.site")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell minWidth={80}>
          <TextButton
            size={"sm"}
            color={"table"}
            iconRight={"arrowAltRight"}
            linkTo={`${hrefLang}/new/proxy/${row.siteAlias}/`}
          >
            {t("proxyPage.content.table.site.btn")}
          </TextButton>
        </BoxCell>
      ),
      minWidth: "80px"
    }
  ];

  useEffect(() => {
    if (!userChoice?.isDataLoaded) {
      getUserChoice(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledUserChoiceSpase>
      <StyledUserChoiceTitle tag={"h3"}>
        {t("mainPage.userChoice.title")}
      </StyledUserChoiceTitle>

      <StyledUserChoiceTable
        data={userChoice?.data}
        columns={columns}
        progressPending={!userChoice?.isDataLoaded}
        progressComponent={<SkeletonUserChoise cells={10} />}
        persistTableHead={userChoice?.isDataLoaded}
        noDataComponent={<NoDataComponent />}
      />
    </StyledUserChoiceSpase>
  );
};
