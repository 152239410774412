import styled from "@emotion/styled";

import Container from "components/common/New/Container/Container";

export const ProxyPageContainer = styled(Container)`
  padding-bottom: 64px !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 120px !important;
  }
`;
