import { useTranslation } from "react-i18next";
import axios from "axios";
import { AllActions } from "store/reducers/AllActions";
import FAQ from "components/common/New/FAQ/FAQ";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { faqToolFingerprint } from "utils/constants/questionsAndAnswers";

import animation from "assets/animations/port_scanner.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  portScannerHeroImageMobile,
  portScannerHeroImageMobile2x
} from "assets/img";

import { PortScanner } from "./PortScanner/PortScanner";

import styles from "./PortScannerPage.module.scss";

const PortScannerPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Main
        title={t("portScanner.main.title")}
        subtitle={t("portScanner.main.subtitle")}
        button={false}
        images={{
          mobile1x: portScannerHeroImageMobile,
          mobile2x: portScannerHeroImageMobile2x
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        containerClassName={styles.hero__container}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-123}
            right={-56}
            bottom={-173}
            left={-56}
          />
        }
      />
      <PortScanner />
      <FAQ
        list={faqToolFingerprint}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        className={styles.faq}
        tools
      />
      <SeoSection />
    </>
  );
};

export default PortScannerPage;

PortScannerPage.getServerSideState = async (store, params, api) => {
  const { data } = await axios.post(`${api}/api/front/faqs`, {
    pageTag: params.tag,
    languageCode: params.languageCode,
    params: params.params
  });

  if (data) {
    store.dispatch(AllActions.setFaqContent({ data, pageTag: params.tag, languageCode: params.languageCode }));
  }
};
