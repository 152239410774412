import { Fragment } from "react";

import { useTranslation } from "react-i18next";

import FormBlock from "pages/New/Dashboard/components/FormBlock/FormBlock";

import RadioGroup from "components/forms/New/RadioGroup/RadioGroup";
import { Separator } from "components/ui/New/Separator/Separator";

import {
  StyledBlockFeaturesBody,
  StyledBlockFeaturesItem
} from "./BlockFeatures.styled";

export const BlockFeatures = () => {
  const { t } = useTranslation();

  const featuresData = [
    {
      name: "freeTest",
      label: t("proxy.freeTest.label"),
      data: t("yesno", { returnObjects: true }),
      defaultValue: true
    },
    {
      name: "refunds",
      label: t("proxy.refunds.label"),
      data: t("yesno", { returnObjects: true }),
      defaultValue: true
    },
    {
      name: "replacementPossibility",
      label: t("proxy.replacementPossibility.label"),
      data: t("yesno", { returnObjects: true }),
      defaultValue: true
    },
    {
      name: "affiliateProgram",
      label: t("proxy.affiliateProgram.label"),
      data: t("yesno", { returnObjects: true }),
      defaultValue: true
    },
    {
      name: "individual",
      label: t("proxy.individual.label"),
      data: t("yesno", { returnObjects: true }),
      defaultValue: true
    },
    {
      name: "api",
      label: t("proxy.api.label"),
      data: t("yesno", { returnObjects: true }),
      defaultValue: true
    }
  ];

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.features.title")}
      subtitle={t("dashboard.services.addEdit.blocks.features.subtitle")}
    >
      <StyledBlockFeaturesBody>
        {featuresData.map(({ name, label, data, defaultValue }, index) => (
          <Fragment key={`${name}-${index}`}>
            <StyledBlockFeaturesItem>
              <RadioGroup
                key={name}
                data={data}
                name={name}
                label={label}
                defaultValue={defaultValue}
              />
            </StyledBlockFeaturesItem>
            {index !== featuresData.length - 1 ? <Separator /> : null}
          </Fragment>
        ))}
      </StyledBlockFeaturesBody>
    </FormBlock>
  );
};
