import styled from "@emotion/styled";

import Breadcrumbs from "components/common/New/Breadcrumbs/Breadcrumbs";
import { Title } from "components/ui/New/Title/Title";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 0 auto 24px 0;
  padding: 0;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    display: none;
  }

  & ul,
  & ol {
    padding: 0;
    margin: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    display: none;
  }
`;

export const StyledTitleCard = styled(Title)`
  margin-bottom: 48px;
  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    display: none;
    margin-bottom: 0;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: $lg-min) {
    gap: 32px;
  }
`;
