import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ProxyTabFilter } from "components/common/New/ProxyTabFilter/ProxyTabFilter";
import Sidebar from "components/common/New/Sidebar/Sidebar";
import TextButton from "components/ui/New/TextButton/TextButton";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useDispatchedActions } from "hooks";

import { getCountryId, getGoalId, isEqual } from "utils/helpers";

import {
  ProxyCard,
  ProxyContainer,
  ProxyContentContainer,
  ProxyViewMoreBox
} from "./Proxy.styled";
import { ProxyContentTop } from "./ProxyContentTop/ProxyContentTop";
import { ProxyTable } from "./ProxyTable/ProxyTable";

export const Proxy = () => {
  // **Props
  const [searchParams] = useSearchParams();

  // **Redux state
  const { countries, goals } = useSelector(getAllContent);
  const { proxySites } = useSelector(getAllProxySite);
  const { t } = useTranslation();
  const { getProxySites } = useDispatchedActions();

  // **Local state
  const [isLoading, setIsLoading] = useState(!proxySites.isDataLoaded);
  const [filterParams, setFilterParams] = useState({
    country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
    sort: searchParams.get("filter") || "",
    goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
    proxyType: searchParams.get("fpt") ? [searchParams.get("fpt")] : ["IPv4"],
    page: 0,
    size: 10
  });

  const handleSetRowsPerPage = () => {
    setFilterParams({ ...filterParams, size: filterParams.size + 10 });
  };

  useEffect(() => {
    const isEqualFilter = isEqual(proxySites.fetchParams, filterParams);

    if (!isEqualFilter) {
      getProxySites(filterParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  useEffect(() => {
    if (proxySites.isDataLoaded) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxySites.isDataLoaded]);

  useEffect(() => {
    const prevFilterParams = filterParams;
    const currentFilterParams = {
      page: 0,
      country: getCountryId(countries.data, searchParams.get("fc"))?.id || "",
      sort: searchParams.get("filter") || "",
      goal: getGoalId(goals.data, searchParams.get("fg"))?.id || "",
      proxyType: searchParams.get("fpt") ? [searchParams.get("fpt")] : ["IPv4"],
      size: 10
    };
    const isEqualFilter = isEqual(prevFilterParams, currentFilterParams);

    if (!isEqualFilter) {
      setFilterParams({
        ...filterParams,
        ...currentFilterParams
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, countries, goals]);

  useEffect(() => {
    if (proxySites.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxySites.isError]);
  return (
    <section>
      <ProxyContainer>
        <ProxyContentTop />
        <ProxyContentContainer id="proxyContent">
          <ProxyCard shadow>
            <ProxyTabFilter />
            <ProxyTable
              tableData={proxySites.content}
              skip={proxySites.skip}
              isLoading={isLoading}
              withOutPagination={filterParams.size >= proxySites?.totalElements}
            />
            {filterParams.size < proxySites?.totalElements && (
              <ProxyViewMoreBox>
                <TextButton
                  iconRight={"arrowAltRight"}
                  color={"secondary"}
                  size={"md"}
                  onClick={handleSetRowsPerPage}
                >
                  {t("buttons.viewMore")}
                </TextButton>
              </ProxyViewMoreBox>
            )}
          </ProxyCard>
          <Sidebar />
        </ProxyContentContainer>
      </ProxyContainer>
    </section>
  );
};
