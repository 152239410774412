import PT from "prop-types";

import IconsSVG from "components/ui/New/Icon/IconsSVG";

import { ErrorCounter } from "./FormError.styled";

const FormError = ({ message, noMargin }) => (
  <ErrorCounter noMargin={noMargin}>
    <IconsSVG name="inputError" />
    {message}
  </ErrorCounter>
);

FormError.propTypes = {
  message: PT.string.isRequired,
  noMargin: PT.bool
};

FormError.defaultProps = {
  noMargin: false
};
export default FormError;
