import { Promocode } from "components/common/New/Promocodes/Promocode";
import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { useLangUrlDefault } from "hooks";

import {
  StyledOfferItem,
  StyledOfferItemBox,
  StyledOfferItemSiteImage,
  StyledOfferItemTitle
} from "./OfferItem.styled";

export const OfferItem = ({ item, loading }) => {
  const [queryLang] = useLangUrlDefault();
  return (
    <StyledOfferItem>
      <StyledOfferItemTitle>
        {loading ? (
          <>
            <StyledSkeleton width={24} height={24} />
            <StyledSkeleton width={100} height={16} />
          </>
        ) : (
          <>
            <StyledOfferItemSiteImage
              src={item?.imageUrl || "/img/ui/placeholder.svg"}
              loading="lazy"
              alt=""
              aria-hidden="true"
            />

            {item?.siteName}
          </>
        )}
      </StyledOfferItemTitle>
      <StyledOfferItemBox>
        <Promocode
          variant={"dark"}
          description={item?.description?.[queryLang] || ""}
          promocode={item?.promocode}
          title={item?.siteName}
          time={item?.endDate}
          eternal={item?.eternal}
          image={null}
          siteId={item?.siteId}
          loading={loading}
        />
      </StyledOfferItemBox>
    </StyledOfferItem>
  );
};
