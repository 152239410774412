import { useState } from "react";

import { useTranslation } from "react-i18next";

import { CardInfo } from "pages/New/Dashboard/components/CardInfo/CardInfo";

import LabelCountry from "components/ui/New/LabelContry/LabelCountry";

import { useLangUrlDefault } from "hooks";

import {
  StyledAdvantagesHeader,
  StyledAdvantagesList,
  StyledAdvantagesSelect,
  StyledAdvantagesTitle
} from "./Advatages.styled";

export const Advantages = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const [lang, setLang] = useState(queryLang);

  const options = [
    { value: "en", label: <LabelCountry code="en">English</LabelCountry> },
    { value: "ru", label: <LabelCountry code="ru">Russian</LabelCountry> },
    { value: "ua", label: <LabelCountry code="ua">Ukrainian</LabelCountry> }
  ];

  return (
    <CardInfo>
      <StyledAdvantagesHeader>
        <StyledAdvantagesTitle>
          {t("dashboard.services.addEdit.blocks.type.advantages.title")}
        </StyledAdvantagesTitle>
        <StyledAdvantagesSelect
          name={"lang"}
          defaultValue={queryLang}
          renderValue={(selected) =>
            options.find((option) => option.value === selected?.value)?.label
          }
          onChange={setLang}
          options={options}
        />
      </StyledAdvantagesHeader>

      {options.map((option) => (
        <StyledAdvantagesList
          key={option.value}
          hidden={option.value !== lang}
          name={`advantages.${option.value}`}
          placeholder={t(
            "dashboard.services.addEdit.blocks.type.advantages.placeholder"
          )}
          addButtonText={t(
            "dashboard.services.addEdit.blocks.type.advantages.add"
          )}
        />
      ))}
    </CardInfo>
  );
};
