// import { useState } from "react";
import { useTranslation } from "react-i18next";

// import { toast } from "react-toastify";
import Button from "components/ui/New/Button/Button";
import Modal from "components/ui/New/Modal/Modal";

import {
  StyledModalBody,
  StyledModalButtons,
  StyledModalImage,
  StyledModalSubtitle
} from "./ModalConfirm.styled";

export const ModalConfirm = ({
  open,
  close,
  isDeleting,
  srcX1,
  srcX2,
  subtitle,
  onConfirm,
  pictureVariant
}) => {
  const { t } = useTranslation();

  const cancelHandler = (value) => {
    close?.(value);
  };

  const confirmHandler = () => onConfirm?.();

  return (
    <Modal
      size={"sm"}
      open={open}
      setOpen={close}
      mobilePosition={"bottom"}
      maxWidth={544}
    >
      <StyledModalBody>
        <StyledModalImage pictureVariant={pictureVariant}>
          <source srcSet={`${srcX1} 1x, ${srcX2} 2x`} type="image/png" />
          <img src={srcX1} alt={"image delete"} />
        </StyledModalImage>
        <StyledModalSubtitle>{subtitle}</StyledModalSubtitle>
        <StyledModalButtons>
          <Button
            variant={"danger"}
            onClick={confirmHandler}
            fullWidth
            loading={isDeleting}
          >
            {t("forms.buttons.delete")}
          </Button>
          <Button
            variant={"secondary"}
            onClick={() => cancelHandler(false)}
            fullWidth
            loading={isDeleting}
          >
            {t("forms.buttons.cancel")}
          </Button>
        </StyledModalButtons>
      </StyledModalBody>
    </Modal>
  );
};
