/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";

import Icon from "components/ui/New/Icon/Icon";

export const LanguagesContainer = styled.div`
  padding: 0.8rem;
  cursor: pointer;
  transition: color 0.5s ease, background-color 0.5s ease;
  &:hover,
  &:focus {
    ${(p) =>
      p.bgHoverColor === "white"
        ? `background-color: ${p.theme.colors.white};
  border-radius: 8px;`
        : ""}
    color: ${(props) =>
      props.variant === "dark" ? "white" : props.theme.colors["neutral-800"]};
    transition: color 0.5s ease, background-color 0.5s ease;
    > div {
      color: ${(props) =>
        props.variant === "dark" ? "white" : props.theme.colors["neutral-800"]};
      transition: color 0.5s ease;
    }
    > svg {
      color: ${(props) =>
        props.variant === "dark" ? "white" : props.theme.colors["neutral-800"]};
      transition: color 0.5s ease;
    }
  }
`;

export const LanguagesInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-weight: 400;
  line-height: 89%;
  text-align: left;
  height: 2.8rem;
  width: 100%;
  transition: color 0.5s ease;
  color: ${(props) => (props.variant === "dark" ? "#ffffffcc" : "#2A2A2DE5")};
`;

export const Title = styled.div`
  margin-right: 1rem;
  text-transform: capitalize;
`;

export const TranslateIcon = styled(Icon)`
  margin-right: 0.4rem;
  transition: transform 0.5s ease;
  width: 24px;
  height: 24px;
`;

export const ArrowIcon = styled(Icon)`
  transform: ${(props) => (props.isOpen ? "rotate(-180deg)" : "rotate(0deg)")};
  transition: transform 0.5s ease;
  width: 20px;
  height: 20px;
`;

export const Current = styled.div`
  position: relative;
  margin-right: 0.4rem;
  text-transform: capitalize;
`;

export const List = styled.ul`
  position: absolute;
  width: 100%;
  border-radius: 0.8rem;
  left: -0.5rem;
  top: 4rem;
  z-index: 5;
  margin-top: 0.4rem;
  margin-bottom: 0;
  max-height: 0;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(-0.6rem)"};
  transition: max-height 1s ease, opacity 0.5s ease, transform 0.5s ease,
    visibility 0.5s;
  padding: 0.8rem;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: 16rem;
    box-shadow: 0 4px 25px rgb(0 0 0 / 10%);
    max-height: ${(props) => props.isOpen && "200px"};
    backdrop-filter: ${(props) => props.isOpen && "blur(16.200000762939453px)"};
    background-color: ${(props) =>
      props.variant === "dark"
        ? `${props.theme.colors["background-color-black"]} !important`
        : "#FCFCFC"};
  }

  @media (max-width: $md-max) {
    max-height: ${(props) => props.isOpen && "300px"};
    background: ${(props) => props.isOpen && "transparent"};
    margin-top: ${(props) => props.isOpen && "0"};
    padding-bottom: ${(props) => props.isOpen && "48px"};
  }
`;

export const Item = styled.li`
  color: ${(props) =>
    props.variant === "dark"
      ? "rgba(255, 255, 255, 0.5)"
      : "rgba(42, 42, 45, 0.9)"};
  margin-bottom: 2px;
  transition: background-color 0.5s ease;
  width: 100%;
  border-radius: 0.8rem;
  cursor: ${(props) => (props.active ? "default" : "pointer")};
  background-color: ${(props) =>
    props.active
      ? props.variant === "dark"
        ? "#ffffff14"
        : "white"
      : "transparent"};

  & button {
    color: ${(props) =>
      props.active
        ? props.variant === "dark"
          ? "white"
          : props.theme.colors["neutral-800"]
        : "inherit"};
  }

  &:hover,
  &:focus {
    background-color: ${(props) =>
      props.variant === "dark" ? "#ffffff14" : "white"};
    transition: background-color 0.5s ease;
    border-radius: 0.8rem;

    & button {
      color: ${(props) =>
        props.variant === "dark" ? "white" : "var(--neutral-800)"};
      transition: color 0.5s ease;
    }
  }
`;

export const Button = styled.button`
  display: inline-block;
  padding: 1rem;
  height: 3.6rem;
  width: 100%;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
`;
