import styled from "@emotion/styled";

import TextButton from "components/ui/New/TextButton/TextButton";

export const StyledShowAllButton = styled(TextButton)`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 170%;

  & svg {
    width: 20px;
    height: 20px;
  }
`;
