import styled from "@emotion/styled";

export const StyledBlockFeaturesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledBlockFeaturesItem = styled.div`
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${(p) => p.theme.colors["neutral-800"]};
  }

  & [class*="RadioGroup_group_label"] {
    color: var(--neutral-800);

    margin-bottom: 0;
    width: 50%;

    color: var(--neutral-600);
    font-family: var(--font-family-text);
    text-transform: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-right: 24px;
  }

  & [class*="RadioGroup_input__wrapper"] {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (min-width: $lg-min) {
      gap: 40px;
    }
  }

  & [class*="RadioGroup_label"] {
    color: var(--neutral-800);
    font-family: var(--font-family-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  & [class*="RadioGroup_input"] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='9.5' stroke='%23D4DAE5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;

    &:checked {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Ccircle cx='12' cy='12' r='7' fill='white' stroke='%238FC846' stroke-width='6'/%3E%3C/svg%3E");
    }
  }
`;
