import { useSelector } from "react-redux";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";

import Skeleton from "react-loading-skeleton";
import { ArticleNavigation } from "./ArticleNavigation/ArticleNavigation";
import { Body } from "./Body/Body";
import { Info } from "./Info/Info";

import styles from "./Article.module.scss";

export const Article = () => {
  // **Redux state
  const { content } = useSelector(getAllBlogInner);

  return (
    <>
      <h1 className={styles.heading}>
        {content.data?.title ? content.data?.title : <Skeleton width="100%" />}
      </h1>
      <Info data={content?.data} />
      <Body />
      <ArticleNavigation />
    </>
  );
};
