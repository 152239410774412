import { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import Container from "components/common/New/Container/Container";
import { Progress } from "components/common/New/Progress/Progress";
import { Input } from "components/forms/New/Input/Input";
import Button from "components/ui/New/Button/Button";
import Icon from "components/ui/New/Icon/Icon.jsx";
import SelectNew from "components/ui/New/Select/SelectNew";
import { Title } from "components/ui/New/Title/Title";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors.js";

import { useLangUrlDefault } from "hooks/useLangUrlDefault.js";
import { useUserIp } from "hooks/useUserIp";

import { headersObjectArrayHelpers, removeHttp } from "utils/helpers";
import { getSelectCountryOptions } from "utils/helpers/getSelectCountryOptions.helper.js";
import { HttpHeadersCheckSchema } from "utils/validation/httpHeadersCheck.validation.js";

import HttpHeaderCheckItem from "./HttpHeaderCheckItem.jsx";
import { ResultsTable } from "./ResultsTable/ResultsTable.jsx";

import styles from "./HttpHeaderCheck.module.scss";

const mockData = [
  {
    header: "x-luminati-ip",
    value: "216.194.91.212"
  },
  {
    header: "x-luminati-timeline",
    value:
      "z413-init:0,auth:21,dns_resolve:9,ipc_find:35,ext_conn:27,zlum_ztun_conn:0,ztun_conn:13"
  },
  {
    header: "x-brd-ip",
    value: "216.194.91.212"
  },
  {
    header: "x-brd-ip",
    value:
      "z413-init:0,auth:21,dns_resolve:9,ipc_find:35,ext_conn:27,zlum_ztun_conn:0,ztun_conn:13"
  },
  {
    header: "date",
    value: "Wed, 24 Jan 2024 16:02:17 GMT"
  },
  {
    header: "content-type",
    value: "text/html; charset=UTF-8"
  },
  {
    header: "transfer-encoding",
    value: "chunked"
  },
  {
    header: "connection",
    value: "keep-alive"
  },
  {
    header: "x-luminati-ip",
    value: "216.194.91.212"
  },
  {
    header: "x-luminati-timeline",
    value:
      "z413-init:0,auth:21,dns_resolve:9,ipc_find:35,ext_conn:27,zlum_ztun_conn:0,ztun_conn:13"
  },
  {
    header: "x-brd-ip",
    value: "216.194.91.212"
  },
  {
    header: "x-brd-ip",
    value:
      "z413-init:0,auth:21,dns_resolve:9,ipc_find:35,ext_conn:27,zlum_ztun_conn:0,ztun_conn:13"
  },
  {
    header: "date",
    value: "Wed, 24 Jan 2024 16:02:17 GMT"
  },
  {
    header: "content-type",
    value: "text/html; charset=UTF-8"
  },
  {
    header: "transfer-encoding",
    value: "chunked"
  },
  {
    header: "connection",
    value: "keep-alive"
  }
];

export const HttpHeaderCheck = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [url, setUrl] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [country, setCountry] = useState("");

  const userIp = useUserIp();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const { headers } = await axios.get(process.env.REACT_APP_URL);

      if (headers && userIp) {
        const newHeaders = headersObjectArrayHelpers(headers);

        setResult(newHeaders);
        setIpAddress(userIp.ipAddress);

        const siteUrl = removeHttp(process.env.REACT_APP_URL);

        setUrl(siteUrl);
        setIsLoading(false);
      }
    })();
  }, [userIp]);

  const { t } = useTranslation();

  const [queryLang] = useLangUrlDefault();

  const { countries } = useSelector(getAllContent);

  const defaultValues = { country: "" };
  const methods = useForm(
    {
      resolver: yupResolver(
        HttpHeadersCheckSchema(t("httpHeadersChecker", { returnObjects: true }))
      )
    },
    defaultValues
  );

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (data) => {
    try {
      setIsLoading(true);

      setUrl(data.url);

      setResult(mockData);
      methods.reset();
      setCountry("");

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      // setIsLoading(false);
    }
  };

  const checkList = useMemo(
    () => [
      {
        title: t("httpHeadersChecker.site"),
        image: "img/ui/site.svg",
        value: url
      },
      {
        title: t("httpHeadersChecker.ipAddress"),
        image: "img/ui/ip.svg",
        value: ipAddress
      }
    ],
    [ipAddress, t, url]
  );

  const analyzingWrapper = (className) => (
    <div className={className}>
      <Title tag="h2" className={styles.title}>
        {t("dnsLeak.analyzing")}
      </Title>
      <div className={styles.additional_text}>
        <Icon name="loading" loading={isLoading} className={styles.loading} />
        <span>{t("dnsLeak.pleaseWait")}</span>
      </div>
      <div className={styles.progress__wrapper}>
        <Progress isLoading={isLoading} />
      </div>
    </div>
  );

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.form__wrapper}>
          <div className={styles.form}>
            <div className={styles.image__wrapper}>
              <picture>
                <source
                  srcSet="/img/http-headers-check/http-header-check-image.svg"
                  media="(min-width: 993px)"
                />
                <source
                  srcSet="/img/http-headers-check/http-header-check-image-mobile.svg"
                  media="(max-width: 992px)"
                />
                <img
                  src="/img/http-headers-check/http-header-check-image.svg"
                  alt={t("httpHeadersChecker.httpHeadersCheck")}
                  className={styles.form__image}
                />
              </picture>
            </div>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Title tag="h2" className={styles.title}>
                  {t("httpHeadersChecker.httpHeadersCheck")}
                </Title>
                <div className={styles.input}>
                  <Input
                    name="url"
                    type="text"
                    placeholder={t("httpHeadersChecker.form.enterWebsite")}
                    label={t("httpHeadersChecker.form.enterWebsite")}
                    showError
                  />
                </div>
                <div className={styles.input}>
                  <SelectNew
                    name="country"
                    id="country"
                    value={country}
                    placeholder={t("httpHeadersChecker.form.selectCountry")}
                    label={t("httpHeadersChecker.form.selectCountry")}
                    className={styles.select}
                    onChange={setCountry}
                    options={getSelectCountryOptions(
                      countries,
                      queryLang,
                      styles
                    )}
                  />
                </div>
                <Button
                  className={styles.button}
                  type="submit"
                  loading={isLoading}
                  {...(isLoading && { iconLeft: "loading" })}
                  fullWidth
                >
                  {isLoading
                    ? t("portScanner.form.loading")
                    : t("httpHeadersChecker.form.button")}
                </Button>
              </form>
            </FormProvider>
            <div className={styles.info_message}>
              <img src="img/icons/information.svg" alt="Info icon" />
              <span>{t("httpHeadersChecker.form.info")}</span>
            </div>
          </div>
          {isLoading && analyzingWrapper(styles.analyzing_mobile)}
        </div>

        <div className={isLoading ? styles.results__loading : styles.results}>
          {isLoading
            ? analyzingWrapper(styles.analyzing_desktop)
            : result && (
                <div className={styles.list__wrapper}>
                  <ul className={styles.list}>
                    {checkList.map((item) => (
                      <HttpHeaderCheckItem key={item.title} {...item} />
                    ))}
                  </ul>
                  <div className={styles.table__wrapper}>
                    <ResultsTable data={result} isLoading={isLoading} />
                    <div className={styles.gradient} />
                  </div>
                </div>
              )}
        </div>
      </div>
    </Container>
  );
};
