import axios from "axios";
import { useTranslation } from "react-i18next";

import Button from "../../../../components/ui/New/Button/Button";
import { useLangUrlDefault } from "../../../../hooks";
import { AllActions } from "../../../../store/reducers/AllActions";
import {
  StyledAuthTitle,
  StyledSubTitle,
  StyledSuccessImage,
  StyledSuccessPicture
} from "../AuthPages.styled";

const FeedbackSuccess = () => {
  // **Redux state
  const { t } = useTranslation();

  const [, hrefLang] = useLangUrlDefault();

  return (
    <>
      <StyledAuthTitle variant={"success"}>
        {t("feedbackPage.success.title")}
      </StyledAuthTitle>
      <StyledSubTitle variant={"success"}>
        {t("feedbackPage.success.subtitle")}
      </StyledSubTitle>
      <StyledSuccessPicture>
        <source
          srcSet="./img/auth/feedback-success-x1.png 1x, ./img/auth/feedback-success-x2.png 2x"
          type="image/png"
        />
        <StyledSuccessImage
          mt={-9}
          mb={18}
          src="./img/auth/feedback-success-x1.png"
          alt="success reistration image"
        />
      </StyledSuccessPicture>
      <Button linkTo={`${hrefLang}/new/`} type={"link"}>
        {t("feedbackPage.success.btn")}
      </Button>
    </>
  );
};

export default FeedbackSuccess;

FeedbackSuccess.getServerSideState = async (store, params, api) => {
  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    params
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
