import styled from "@emotion/styled";

import TextButton from "components/ui/New/TextButton/TextButton";

export const StyledPromoBlockBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.noGap ? "0" : "16px")};
`;

export const StyledButtonText = styled(TextButton)`
  align-self: flex-start;

  & svg {
    transform: rotate(${(p) => (p.open ? "180deg" : "0")});
    transition: all 0.3s;
  }
`;

export const StyledPromoReviewImage = styled.picture`
  width: 100%;
  max-height: ${(p) => (p.open ? "500px" : "0")};
  margin-top: ${(p) => (p.open ? "16px" : "0")};
  overflow: hidden;
  transition: all 0.3s;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
