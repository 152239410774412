import styled from "@emotion/styled";

export const StyledSortBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-bottom: 24px;

  font-size: ${({ theme }) => theme.fontSizes.s};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-style: ${({ theme }) => theme.fontStyle.normal};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    width: fit-content;
    align-self: flex-end;
  }
  & > div {
    min-width: 150px;
  }
`;

export const StyledSortLabel = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors["neutral-400"]};
  line-height: 150%;
`;
