import styled from "@emotion/styled";

import TextButton from "components/ui/New/TextButton/TextButton";

export const StyledReplyPart = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    gap: 24px;
  }
`;

export const StyledtextButton = styled(TextButton)`
  font-size: ${(p) => p.theme.fontSizes.s};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  font-style: ${(p) => p.theme.fontStyle.normal};
  line-height: 170%;

  color: ${(p) =>
    p.isActive ? p.theme.colors["main-600"] : p.theme.colors["neutral-500"]};

  ${(p) =>
    p.variant === "answer"
      ? `
    &:hover,
    &:focus {
        color: ${p.theme.colors["main-600"]};
    }
    `
      : ""}

  & svg {
    width: 20px;
    height: 20px;
  }
`;
