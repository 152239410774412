import styled from "@emotion/styled";

export const StyledLabel = styled.span`
  color: ${(p) => p.theme.colors["neutral-300"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 140%;
  margin-bottom: 12px;
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 40px;
  }
`;

export const StyledCheckboxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    justify-content: flex-start;
  }
  & > * {
    width: fit-content;
  }
`;

export const StyledDateBox = styled.div`
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    min-width: 250px;
    width: 50%;
  }
`;
