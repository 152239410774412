import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getAllPromocodes } from "store/reducers/PromocodesReducer/Promocodes.selectors";

import { useDispatchedActions } from "hooks";

import Container from "../../../../components/common/New/Container/Container";

import { OfferItem } from "./OfferItem/OfferItem";
import {
  StyledSpesialOfferCard,
  StyledSpesialOffersDescription,
  StyledSpesialOffersHeader,
  StyledSpesialOffersListBox,
  StyledSpesialOffersTitle
} from "./SpesialOffer.styled";

export const SpecialOffer = () => {
  const { t } = useTranslation();
  const { getSpecialPromocodes } = useDispatchedActions();

  const { special } = useSelector(getAllPromocodes);

  useEffect(() => {
    if (!special?.isDataLoaded) {
      getSpecialPromocodes();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section>
      <Container>
        <StyledSpesialOfferCard shadow size={"large"}>
          <StyledSpesialOffersHeader>
            <StyledSpesialOffersTitle>
              {t("proxyPage.specialOffer.title")}
            </StyledSpesialOffersTitle>
            <StyledSpesialOffersDescription>
              {t("proxyPage.specialOffer.description")}
            </StyledSpesialOffersDescription>
          </StyledSpesialOffersHeader>
          <StyledSpesialOffersListBox>
            <Swiper
              direction="horizontal"
              modules={[Pagination]}
              pagination={{ clickable: true }}
              wrapperTag="ul"
              style={{ overflow: "visible", position: "static" }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                400: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                480: {
                  slidesPerView: 1.2,
                  spaceBetween: 20
                },
                550: {
                  slidesPerView: 1.5,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 36
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 24
                }
              }}
            >
              {special?.isDataLoaded ? (
                <>
                  {special?.data?.length
                    ? special?.data.map((item, index) => (
                        <SwiperSlide
                          key={`special-offer-${index}`}
                          tag="li"
                          style={{
                            height: "auto"
                          }}
                        >
                          <OfferItem item={item} />
                        </SwiperSlide>
                      ))
                    : null}
                </>
              ) : (
                Array.from({ length: 3 }).map((_, index) => (
                  <SwiperSlide
                    key={`special-offer-${index}`}
                    tag="li"
                    style={{
                      height: "auto"
                    }}
                  >
                    <OfferItem loading />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </StyledSpesialOffersListBox>
        </StyledSpesialOfferCard>
      </Container>
    </section>
  );
};
