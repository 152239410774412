import { useTranslation } from "react-i18next";
import axios from "axios";
import { AllActions } from "store/reducers/AllActions";
import Container from "components/common/New/Container/Container";
import FAQ from "components/common/New/FAQ/FAQ";
import { Main } from "components/common/New/Main/Main";
import SeoSection from "components/common/New/SeoSection/SeoSection";
import { Rive } from "components/ui/New/Rive/Rive";

import { faqToolPortScanner } from "utils/constants/questionsAndAnswers";

import animation from "assets/animations/tracing_ip.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  tracingIpHeroImageMobile
} from "assets/img";

import { TraicingIp } from "./TracingIp/TracingIp";

import styles from "./TracingIpPage.module.scss";

const TracingIpPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Main
        title={t("tracingIp.main.title")}
        subtitle={t("tracingIp.main.subtitle")}
        button={false}
        images={{
          mobile1x: tracingIpHeroImageMobile,
          mobile2x: tracingIpHeroImageMobile
        }}
        className={styles.hero}
        imageClassName={styles.hero__image}
        containerClassName={styles.hero__container}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-93}
            right={-148}
            bottom={-93}
            left={-98}
          />
        }
      />
      <Container className={styles.container}>
        <TraicingIp />
      </Container>
      <FAQ
        list={faqToolPortScanner}
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        className={styles.faq}
      />
      <SeoSection />
    </>
  );
};

export default TracingIpPage;

TracingIpPage.getServerSideState = async (store, params, api) => {
  const { data } = await axios.post(`${api}/api/front/faqs`, {
    pageTag: params.tag,
    languageCode: params.languageCode,
    params: params.params
  });

  if (data) {
    store.dispatch(AllActions.setFaqContent({ data, pageTag: params.tag, languageCode: params.languageCode }));
  }
};
