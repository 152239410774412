import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "utils/constants";

export const ServiceSchema = (trns, languages, proxyForLang) => {
  const languagesValidation = {};

  languages.forEach((lang) => {
    languagesValidation[lang.value] = yup.string();
  });

  const proxyTypesValidation = {};
  proxyForLang.forEach((data) => {
    proxyTypesValidation[data.type] = yup.boolean();
  });

  const regexpUrlWithoutHttps =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

  // const regexpIP =
  //   /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const proxyValidation = {};
  proxyForLang.forEach((data) => {
    if (data.checked) {
      if (data.type === "ResidentialProxy") {
        proxyValidation[data.type] = yup.object().shape({
          link: yup
            .string()
            .required(trns.link.rules.required)
            .matches(regexpUrlWithoutHttps, trns.link.rules.url),
          minRent: yup.string().required(trns.minRent.rules.required),
          minPrice: yup
            .string()
            .required(trns.minPrice.rules.required)
            .test(
              "positive",
              trns.minPrice.rules.positive,
              (value) => Number(value) > 0
            ),
          pullIp: yup
            .string()
            .required(trns.pullIP.rules.required)
            .test(
              "positive",
              trns.pullIP.rules.positive,
              (value) => Number(value) > 0
            ),
          minGb: yup
            .string()
            .required(trns.minGB.rules.required)
            .test(
              "positive",
              trns.minGB.rules.positive,
              (value) => Number(value) > 0
            )
            .required(trns.minGB.rules.required)
        });
      } else {
        proxyValidation[data.type] = yup.object().shape({
          link: yup
            .string()
            .required(trns.link.rules.required)
            .matches(regexpUrlWithoutHttps, trns.link.rules.url),
          minRent: yup.string().required(trns.minRent.rules.required),
          minPrice: yup
            .string()
            .test(
              "positive",
              trns.minPrice.rules.positive,
              (value) => Number(value) > 0
            )
            .required(trns.minPrice.rules.required),
          locations: yup
            .array()
            .min(1, trns.countries.rules.required)
            .required(trns.countries.rules.required)
        });
      }
    }
  });

  return yup.object().shape({
    link: yup
      .string()
      .required(trns.link.rules.required)
      .matches(regexpUrlWithoutHttps, trns.link.rules.url),
    description: yup
      .object()
      .shape(languagesValidation)
      .test(
        "description-test",
        trns.allLanguages.rules.allMustBeFilled,
        (obj) => Object.values(obj).every(Boolean)
      )
      .test({
        name: "description-min",
        exclusive: true,
        // params: { min: 50 },
        message: trns.description.rules.minLength,
        test: (value) =>
          Object.values(value)
            .filter(Boolean)
            .every((it) => it.length >= 50)
      })
      .test({
        name: "description-max",
        exclusive: true,
        // params: { max: 100 },
        message: trns.description.rules.maxLength,
        test: (value) =>
          Object.values(value)
            .filter(Boolean)
            .every((it) => it.length <= 500)
      }),
    proxyTypes: yup
      .object()
      .shape(proxyTypesValidation)
      .test("atLeastOne", trns.proxyType.rules.minOne, (value) => {
        const values = Object.values(value);
        return values.some(Boolean);
      }),
    proxy: yup.object().shape(proxyValidation),
    promocode: yup.string(),
    promocodeDescription: yup.object().when("promocode", {
      is: (promocode) => promocode?.length > 0,
      then: () =>
        yup
          .object()
          .shape(languagesValidation)
          .test(
            "description-test",
            trns.allLanguages.rules.allMustBeFilled,
            (obj) => Object.values(obj).every(Boolean)
          )
          .test({
            name: "description-min",
            exclusive: true,
            // params: { min: 50 },
            message: trns.description.rules.minLength,
            test: (value) =>
              Object.values(value)
                .filter(Boolean)
                .every((it) => it.length >= 50)
          })
          .test({
            name: "description-max",
            exclusive: true,
            // params: { max: 100 },
            message: trns.description.rules.maxLength,
            test: (value) =>
              Object.values(value)
                .filter(Boolean)
                .every((it) => it.length <= 100)
          }),
      otherwise: () => yup.object().shape(languagesValidation)
    }),
    dateRange: yup
      .array()
      .of(yup.date())
      .when("eternal", {
        is: (eternal) => eternal === "date",
        then: () =>
          yup
            .array()
            .of(yup.date())
            .test("allFilled", trns.date.rules.allRequired, (value) =>
              value.every(Boolean)
            )
            .test(
              "endDateGreaterNow",
              trns.date.rules.endDateGreaterNow,
              (value) => {
                const [, endDate] = value;
                return endDate?.getTime() > new Date().getTime();
              }
            ),
        otherwise: () => yup.array()
      }),
    socialNetworks: yup.object().shape({
      email: yup
        .string()
        .required(trns.email.rules.required)
        .matches(
          process.env?.REACT_APP_MODE === "prod"
            ? EMAIL_REGEX
            : EMAIL_STAGE_REGEX,
          trns.email.rules.email
        ),
      telegram: yup.string(),
      youTube: yup.string(),
      discord: yup.string(),
      twitter: yup.string(),
      reddit: yup.string()
    })
  });
};
