import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import PromoButtons from "./PromoButtons/PromoButtons";
import PromoTitle from "./PromoTitle/PromoTitle";
import {
  StyledDescription,
  StyledInfoBox,
  StyledPromocode
} from "./Promocode.styled";

export const Promocode = ({
  description,
  promocode,
  siteId,
  title,
  image,
  time,
  eternal,
  variant,
  loading
}) => (
  <StyledPromocode variant={variant}>
    <StyledInfoBox variant={variant}>
      <PromoTitle
        variant={variant}
        title={title}
        image={image}
        time={time}
        eternal={eternal}
        loading={loading}
      />

      <StyledDescription variant={variant}>
        {loading ? <StyledSkeleton height={14} count={2} /> : description}
      </StyledDescription>
    </StyledInfoBox>
    <PromoButtons
      variant={variant}
      promocode={promocode}
      siteId={siteId}
      loading={loading}
    />
  </StyledPromocode>
);
