import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { RenderReviews } from "components/common/New/RenderReviews/RenderReviews";
import Select from "components/ui/New/Select/Select";
import { SkeletonReviews } from "components/ui/New/Skeleton/SkeletonReviews/SkeletonReviews";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useDispatchedActions } from "hooks";

import { BaseScore } from "./BaseScore/BaseScore";
import {
  StyledListReviewContainer,
  StyledListReviewHeader,
  StyledListReviewSortBox,
  StyledListReviewSortLabel,
  StyledListReviewSortSelectBox
} from "./ListReview.styled";

export const ListReview = ({ active, captchaRef, setTokenCaptcha }) => {
  const { t } = useTranslation();
  const { reviews, site } = useSelector(getAllProxySite);
  // **Props
  const { alias } = useParams();
  const options = t("proxySitePage.content.reviews.top.sortList", {
    returnObjects: true
  }).map((sortType) => ({
    label: sortType.label,
    value: sortType.param
  }));

  // **Dispatch
  const { getProxySiteReviews } = useDispatchedActions();

  // **Local state
  const [pageParams, setPageParams] = useState(
    reviews.fetchedParams?.siteId === alias
      ? reviews.fetchedParams
      : {
          page: 0,
          size: 4,
          sort: "creationDate",
          siteId: alias
        }
  );

  const changeSortType = (type) => {
    setPageParams({ ...pageParams, sort: type });
  };

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      page: page - 1
    });
  };

  const incrementPageSizes = () => {
    setPageParams({ ...pageParams, size: pageParams.size + 4 });
  };

  const fetchProxyRevies = () => {
    getProxySiteReviews({
      ...pageParams,
      siteId: alias
    });
  };

  // Handling content
  useEffect(() => {
    if (
      reviews.fetchedParams?.sort !== pageParams?.sort ||
      reviews.fetchedParams?.page !== pageParams?.page ||
      reviews.fetchedParams?.size !== pageParams?.size ||
      reviews.fetchedParams?.siteId !== pageParams?.siteId ||
      !reviews?.isDataLoaded
    ) {
      fetchProxyRevies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams]);

  // Handling error
  useEffect(() => {
    if (reviews.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.isError]);
  return (
    <StyledListReviewContainer active={active}>
      <StyledListReviewHeader>
        <StyledListReviewSortBox>
          <StyledListReviewSortLabel>
            {t("proxySitePage.content.reviews.top.title")}:
          </StyledListReviewSortLabel>
          <StyledListReviewSortSelectBox>
            <Select
              value={pageParams.sort}
              onChange={changeSortType}
              options={options}
            />
          </StyledListReviewSortSelectBox>
        </StyledListReviewSortBox>
        <BaseScore
          loading={!site?.isDataLoaded || !reviews?.isDataLoaded}
          score={site.data?.rating}
          total={reviews.data?.totalElements}
        />
      </StyledListReviewHeader>
      {reviews?.isDataLoaded ? (
        <RenderReviews
          siteTitle={site.data?.title}
          reviews={reviews.data?.content}
          totalElements={reviews.data?.totalElements}
          pageSize={pageParams.size}
          currentPage={pageParams.page + 1}
          changeHandler={fetchProxyRevies}
          onChangePage={pageChangeHandler}
          handlerShowMore={incrementPageSizes}
          paginationDisabled={2}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
        />
      ) : (
        <SkeletonReviews cells={4} />
      )}
    </StyledListReviewContainer>
  );
};
