import styled from "@emotion/styled";

import TextButton from "components/ui/New/TextButton/TextButton";

export const StyledShowMoreButton = styled(TextButton)`
  margin: 24px auto 12px auto;

  & svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-bottom: 20px;
  }
`;

export const StyledPaginationBox = styled.div`
  margin-top: ${(p) => (p.isDashboard ? "24px" : "0")};
  width: 100%;
  & > * {
    padding-top: 0 !important;
    @media (max-width: ${(p) =>
        p.isDashboard
          ? p.theme.screenSizes.mdMax
          : p.theme.screenSizes.lgMax}) {
      & [class*="Pagination_page"],
      & [class*="break"] {
        display: none;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
