import { useEffect } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";

import Button from "components/ui/New/Button/Button";

import { Input } from "../Input/Input";

import styles from "./FormList.module.scss";

export const FormList = ({ name, placeholder, addButtonText, className }) => {
  // ** Hooks
  const { control, watch } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({ control, name });
  const handlerAddNew = () => append("");
  const values = watch(name);
  useEffect(() => {
    if (values?.length > 0 && fields?.length === 0) {
      replace(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <div className={className}>
      <ul className={styles.list}>
        {fields.map((item, index) => (
          <li key={item.id} className={styles.list_item}>
            <Input
              className={styles.list_item_input}
              name={`${name}.${index}`}
              type={"text"}
              rules={{ required: true }}
              placeholder={placeholder}
            />
            <Button
              className={styles.list_item_delete}
              variant={"secondary"}
              type={"icon"}
              iconLeft={"minus"}
              onClick={() => remove(index)}
            />
          </li>
        ))}
      </ul>
      <Button
        fullWidth
        variant={"secondary"}
        className={styles.add_new}
        iconLeft={"plus"}
        onClick={handlerAddNew}
      >
        {addButtonText || "Add new"}
      </Button>
    </div>
  );
};
