import { useId } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Container from "components/common/New/Container/Container";
import Icon from "components/ui/New/Icon/Icon";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import { useUserIp } from "hooks/useUserIp";

import { useBrowserInfo } from "../../../../../hooks";

import { ResultsTable } from "./ResultTable/ResultTable";

import styles from "./BrowserInformation.module.scss";

export const BrowserInformation = () => {
  const { t } = useTranslation();

  const customToastId = useId();
  const tooltipId = useId();

  const userIp = useUserIp();

  const { browserInfo } = useBrowserInfo(userIp);

  const onCopyHandler = () => {
    toast.success(t("notifications.copy"), {
      toastId: customToastId
    });
  };

  return (
    <Container className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <img src="img/ui/disguise-green.svg" className={styles.image} />
          <p className={styles.text}>{t("browserInformation.youreUsing")}</p>
          <h3 className={styles.browser}>
            {userIp?.app}
            {browserInfo.browserVersion.split(".")[0] ||
              userIp?.appMajorVersion}{" "}
            {t("ui.on")} {userIp?.platform}
          </h3>
          <div className={styles.ip__wrapper}>
            <div className={styles.ip}>
              <p className={styles.ip__title}>
                {t("browserInformation.yourIP")}
              </p>
              <div className={styles.ip__address__wrapper}>
                {userIp?.country && (
                  <span
                    className={`fl fl-${userIp?.countryA2?.toLowerCase()} ${
                      styles.flag
                    }`}
                  />
                )}
                {userIp?.ipAddress?.length > 24 ? (
                  <Tooltip
                    id={tooltipId}
                    body={userIp?.ipAddress}
                    place="top-start"
                    className={styles.tooltip}
                  >
                    <span
                      className={`${styles.ip__address} ${styles.text_overflow}`}
                    >
                      {userIp?.ipAddress}
                    </span>
                  </Tooltip>
                ) : (
                  <span className={styles.ip__address}>
                    {userIp?.ipAddress}
                  </span>
                )}
                <CopyToClipboard
                  text={userIp?.ipAddress}
                  onCopy={onCopyHandler}
                >
                  <Icon name="copyAlt" className={styles.icon} size="xlarge" />
                </CopyToClipboard>
              </div>
            </div>
            {userIp?.country && (
              <div className={styles.country}>
                <Icon name="marker" className={styles.icon} size="xlarge" />
                <span>
                  {userIp?.city ? `${userIp?.city},` : ""} {userIp?.country}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.results}>
          <ResultsTable data={userIp} info={browserInfo} />
        </div>
      </div>
    </Container>
  );
};
