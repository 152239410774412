import PT from "prop-types";

import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { Subtitle } from "../../../ui/New/Subtitle/Subtitle";
import { Title } from "../../../ui/New/Title/Title";

import styles from "./SectionHead.module.scss";

export const SectionHead = ({
  title,
  tag,
  description,
  className,
  titleClassName,
  descriptionClassName,
  direction,
  loading
}) => (
  <div className={`${styles?.[direction]} ${className}`}>
    <Title tag={tag} className={titleClassName}>
      {loading ? <StyledSkeleton /> : title}
    </Title>
    {(description || loading) && (
      <Subtitle className={descriptionClassName}>
        {loading ? <StyledSkeleton count={3} /> : description}
      </Subtitle>
    )}
  </div>
);

SectionHead.propTypes = {
  title: PT.string.isRequired,
  tag: PT.string,
  description: PT.string,
  className: PT.string,
  titleClassName: PT.string,
  descriptionClassName: PT.string,
  direction: PT.oneOf(["row", "column"])
};

SectionHead.defaultProps = {
  tag: "h2",
  description: "",
  className: "",
  titleClassName: "",
  descriptionClassName: "",
  direction: "row"
};
