import { Suspense, useEffect } from "react";

import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { Rive } from "components/ui/New/Rive/Rive";

import animFitback from "assets/animations/good_service.riv";
import animAutn from "assets/animations/welcome_to_trustytech.riv";

import Logo from "../../components/ui/New/Logo/Logo";
import { Subtitle } from "../../components/ui/New/Subtitle/Subtitle";
import { Title } from "../../components/ui/New/Title/Title";
import {
  useDispatchedActions,
  useLangUrlDefault,
  useMatchedRoute
} from "../../hooks";
import { getAllUser } from "../../store/reducers/UserReducer/User.selectors";

import styles from "./AuthLayout.module.scss";

// import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

export const AuthLayout = () => {
  const [, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const route = useMatchedRoute();

  // **Redux state
  const { isUserAuthenticated, isRedirectToCabinet } = useSelector(getAllUser);
  // const { pageContent: { current } } = useSelector(getAllContent);
  // **Dispatch
  const { setIsRedirectToCabinet } = useDispatchedActions();

  useEffect(() => {
    if (
      isUserAuthenticated &&
      !isRedirectToCabinet &&
      route.tag !== "feedback"
    ) {
      setIsRedirectToCabinet(false);
      navigate(`${hrefLang}/dashboard/profile/`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthenticated, isRedirectToCabinet]);

  const translate = t("layouts.auth", { returnObjects: true });

  return (
    <section className={styles.section}>
      <Logo color="black" className={styles.logo} />
      <aside className={styles.aside}>
        <Title tag="h3" size="sm" color="white" className={styles.title}>
          {translate?.[route?.tag]?.title || translate.default?.title}
        </Title>
        <Subtitle size="lg" color="white" className={styles.subtitle}>
          {translate?.[route?.tag]?.subtitle || translate.default?.subtitle}
        </Subtitle>
        <div
          className={
            route?.tag === "feedback"
              ? styles.animation_feedback
              : styles.animation_auth
          }
        >
          <Rive
            src={route?.tag === "feedback" ? animFitback : animAutn}
            autoPlay
            top={route?.tag === "feedback" ? -91 : -40}
            left={route?.tag === "feedback" ? -91 : -66}
            bottom={route?.tag === "feedback" ? -96 : -76}
            right={route?.tag === "feedback" ? -91 : -66}
          />
        </div>
      </aside>
      <div className={styles.wrapper}>
        <Suspense fallback>
          <Outlet />
        </Suspense>
      </div>
    </section>
  );
};
