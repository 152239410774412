import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";

import { scrollToWithOffset } from "utils/helpers/scroll.helper";

import styles from "../Article.module.scss";

export const Body = () => {
  const { content } = useSelector(getAllBlogInner);
  const [activeSection, setActiveSection] = useState(null);

  const { t } = useTranslation();

  const observer = useRef(null);

  useEffect(() => {
        // eslint-disable-next-line no-undef
        observer.current = new IntersectionObserver((entries) => {
          const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
          if (visibleSection) {
            setActiveSection(visibleSection.id);
          }
        }, {
          rootMargin: "-50%"
        });

        const sections = document.querySelectorAll("[data-section]");

        sections.forEach((section) => {
          observer.current.observe(section);
        });
        return () => {
          sections.forEach((section) => {
            observer.current.unobserve(section);
          });
        };
      }, [content]);

  // useEffect(() => {
  //   if (content?.data?.anchors?.length && !activeSection) {
  //     setActiveSection(content.data.anchors[0]?.anchor);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [content]);

  const handleAnchorClick = (item) => {
    scrollToWithOffset(item.anchor, -50);
  };

  return (
    <div className={styles.body__wrapper}>
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: content.data.articleBody }}
        />
      {content?.data.anchors?.length ? (
        <div className={styles.summary}>
          <p className={styles.title}>
            {t("blogInnerPage.articleBody.inThisArticle")}
          </p>
          <ul>
            {content.data?.anchors?.map((item) => (
              <li
                key={item.id}
                id={item.title}
                className={
                  activeSection === item.anchor
                    ? styles.summary__item_active
                    : styles.summary__item
                }
                onClick={() => handleAnchorClick(item)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  );
};
