import { useTranslation } from "react-i18next";

import Rating from "components/ui/New/Raiting/Rating";
import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { StyledBaseScore } from "./BaseScore.styled";

export const BaseScore = ({ score, total, loading }) => {
  const { t } = useTranslation();

  const currentCountWords = (count) => {
    let words = "";
    if (count === 1) {
      words = t("proxySitePage.info.base.reviewCountOne");
    } else if (count > 1 && count < 5) {
      words = t("proxySitePage.info.base.reviewCountTwo");
    } else {
      words = t("proxySitePage.info.base.reviewCount");
    }
    return `${count} ${words}`;
  };

  return (
    <StyledBaseScore>
      {!loading ? (
        <>
          <Rating
            rating={score}
            background={false}
            // className={styles.score_amount}
          />
          <span>{`${t(
            "proxySitePage.about.reviews.basedOn"
          )} ${currentCountWords(total)}`}</span>
        </>
      ) : (
        <StyledSkeleton width={150} height={14} />
      )}
    </StyledBaseScore>
  );
};
