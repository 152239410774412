import Container from "components/common/New/Container/Container";

import { mockPortCheckedData } from "utils/constants/mockTableData";

import { CTA } from "./CTA/CTA";
import { PortsTable } from "./PortsTable/PortsTable";
import { PortScannerSlider } from "./PortScannerSlider/PortScannerSlider";
import { TestPort } from "./TestPort/TestPort";
import { PortScannerSteps } from "./PortScannerSteps/PortScannerSteps";

import styles from "./PortScanner.module.scss";

export const PortScanner = () => (
  <section className={styles.section}>
    <Container className={styles.container}>
      <TestPort />
      <PortsTable data={mockPortCheckedData} />
    </Container>
    <PortScannerSlider />
    <CTA />
    <PortScannerSteps />
  </section>
);
