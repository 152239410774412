import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumbs from "components/common/New/Breadcrumbs/Breadcrumbs";

import { getAllBlogInner } from "store/reducers/BlogInnerReducer/BlogInner.selectors";
import { ApiService } from "services";

import { useDispatchedActions, useLangUrlDefault } from "hooks";
import { useBrowserFingerprint } from "hooks/useBrowserFingerprint";
import { AllActions } from "store/reducers/AllActions";

import { BlogInner } from "./BlogInner/BlogInner";

import styles from "./BlogInnerPage.module.scss";

const STATUS_DRAFT = "DRAFT";

const BlogInnerPage = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { alias } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // **Redux state
  const { content } = useSelector(getAllBlogInner);
  const { t } = useTranslation();
  //blogInnerPage, notifications
  const browserFingerprint = useBrowserFingerprint();

  // Dispatch
  const {
    getBlogContentByAlias,
    setErrorBlogInnerContent
  } = useDispatchedActions();

  useEffect(() => {
    getBlogContentByAlias({ alias, languageCode: queryLang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, alias]);

  useEffect(() => {
    if (content?.data?.id && browserFingerprint) {
      ApiService.createCountView(content?.data?.id, browserFingerprint);
    }
  }, [content?.data?.id, browserFingerprint]);

  // Handling error
  useEffect(() => {
    if (content.isError || content?.data?.status === STATUS_DRAFT) {
      toast.error(t("notifications.blogInner.notFoundBlogPost"));
      navigate(`${hrefLang}/new/blog/?tab=all`);
      setErrorBlogInnerContent(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.isError, content?.data?.status]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  }, [pathname]);

  return (
    <>
      <Breadcrumbs
        className={styles.breadcrumbs}
        items={[
          ...t("blogInnerPage.breadcrumbs", { returnObjects: true }),
          { title: content.data?.title || null }
        ]}
      />
      <BlogInner />
    </>
  );
};

export default BlogInnerPage;

BlogInnerPage.getServerSideState = async (store, params, api, req) => {
  const [, identifier] = req._parsedUrl.pathname.split("/").reverse();
  const { data: blogContentByAlias } = await axios.get(
    `${api}/api/front/site/blog/view-article`,
    { params: { alias: identifier, languageCode: params.languageCode } }
  );
  store.dispatch(AllActions.setBlogContentByAlias(blogContentByAlias));
};
