import { createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getAllPromocodes = createAsyncThunk(
  "promocodes/getAllPromocodes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllPromocodes(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getPromocode = createAsyncThunk(
  "promocodes/getPromocode",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.getPromocodeById(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAlternatePromocodes = createAsyncThunk(
  "promocodes/getAlternatePromocodes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAlternatePromocodes();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getSpecialPromocodes = createAsyncThunk(
  "promocodes/getSpecialPromocodes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getSpecialPromocodes();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);
