import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Rive } from "components/ui/New/Rive/Rive";

import { useLangUrlDefault } from "hooks";

import { list, listWithNumbers } from "utils/constants/mockHowTobuyData";

import animationGuide from "assets/animations/proxy_purchasing_guide.riv";
import animationService from "assets/animations/proxy_service.riv";
import {
  howToBuyIpv4ProxyImageMobile,
  howToBuyIpv4ProxyImageMobile2x,
  ipv4ProxyImageMobile,
  ipv4ProxyImageMobile2x
} from "assets/img";

import {
  StyledContainer,
  StyledContent,
  StyledDottedListItem,
  StyledGuideAnimation,
  StyledHeading,
  StyledImage,
  StyledItemDescription,
  StyledItemTitle,
  StyledListItem,
  StyledListTitle,
  StyledSection,
  StyledServiceAnimation,
  StyledSubheading
} from "./HowToBuy.styled";

const HowToBuy = () => {
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();

  const proxyType = searchParams.get("fpt");
  const [queryLang] = useLangUrlDefault();

  const numberedList = useMemo(
    () => (
      <ul>
        {listWithNumbers[queryLang].map((item, index) => (
          <StyledListItem key={index}>
            <StyledItemTitle>{`${index + 1}. ${item.title}`}</StyledItemTitle>
            <StyledItemDescription>{item.description}</StyledItemDescription>
          </StyledListItem>
        ))}
      </ul>
    ),
    [queryLang]
  );

  const dottedList = useMemo(
    () => (
      <ul>
        {list.map((item, index) => (
          <StyledDottedListItem key={index}>
            <div>
              <img src="img/icons/dot.svg" alt="Dot icon" />
            </div>
            {item}
          </StyledDottedListItem>
        ))}
      </ul>
    ),
    []
  );

  return (
    <>
      <StyledSection background="dark">
        <StyledContainer>
          <StyledGuideAnimation>
            <Rive
              src={animationGuide}
              autoPlay
              top={-82}
              right={-80}
              bottom={-81}
              left={-81}
            />
          </StyledGuideAnimation>
          <StyledContent>
            <StyledHeading marginBottom="small">
              {`${t("howToBuy.title")} ${proxyType} ${t("howToBuy.proxy")}`}
            </StyledHeading>
            <StyledSubheading>{t("howToBuy.subtitle")}</StyledSubheading>
            {numberedList}
          </StyledContent>
          <StyledImage variant="dark">
            <source
              srcSet={`${howToBuyIpv4ProxyImageMobile} 1x, ${howToBuyIpv4ProxyImageMobile2x} 2x`}
            />
            <img
              src={howToBuyIpv4ProxyImageMobile2x}
              alt={`${t("howToBuy.title")} ${proxyType} ${t("howToBuy.proxy")}`}
              loading="lazy"
            />
          </StyledImage>
        </StyledContainer>
      </StyledSection>
      <StyledSection background="light">
        <StyledContainer>
          <StyledContent>
            <StyledHeading>
              {`${proxyType} ${t("howToBuy.proxy")}`}
            </StyledHeading>
            <StyledSubheading>{t("howToBuy.proxySubtitle")}</StyledSubheading>
            <StyledListTitle>{t("howToBuy.listTitle")}</StyledListTitle>
            {dottedList}
          </StyledContent>
          <StyledServiceAnimation>
            <Rive
              src={animationService}
              autoPlay
              top={-30}
              right={-39}
              bottom={-62}
              left={-64}
            />
          </StyledServiceAnimation>
          <StyledImage variant="light">
            <source
              srcSet={`${ipv4ProxyImageMobile} 1x, ${ipv4ProxyImageMobile2x} 2x`}
            />
            <img src={ipv4ProxyImageMobile} alt="IPv4 Proxy" loading="lazy" />
          </StyledImage>
        </StyledContainer>
      </StyledSection>
    </>
  );
};

export default HowToBuy;
