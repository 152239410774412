import styled from "@emotion/styled";

import Modal from "components/ui/New/Modal/Modal";

export const StyledCountriesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledCountryLabel = styled.span`
  color: ${(p) => p.theme.colors["neutral-300"]};
  width: 100%;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-bottom: 5px;
`;

export const StyledCountriesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 11px 15px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(p) => {
    if (p.isError) {
      return `${p.theme.colors["red-500"]} !important`;
    }
    if (p.isActive) {
      return p.theme.colors["main-600"];
    }
    return p.theme.colors["neutral-100"];
  }};
  background: ${(p) => p.theme.colors.white};

  &:hover,
  &:focus {
    border-color: ${(p) => p.theme.colors["neutral-800"]};
  }

  & svg[class^="indicator"] {
    width: 24px;
    height: 24px;
    margin-left: 16px;
    transform: rotate(${(p) => (p.isActive ? "180deg" : "0deg")});
    transition: transform 0.3s;
  }
`;

export const StyledCountriesModal = styled(Modal)`
  width: 100%;
  height: auto;

  left: 0;
  transform: none;
  bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;
