import { useTranslation } from "react-i18next";

import FormBlock from "pages/New/Dashboard/components/FormBlock/FormBlock";

import { Checkbox } from "components/forms/New";

import { StyledBlockAuthBody } from "./BlockAuth.styled";

export const BlockAuth = () => {
  const { t } = useTranslation();
  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.auth.title")}
      subtitle={t("dashboard.services.addEdit.blocks.auth.subtitle")}
    >
      <StyledBlockAuthBody>
        <Checkbox name="clientNoAuth" label={t("proxy.auth.noAuth")} />
        <Checkbox name="clientLoginAuth" label={t("proxy.auth.loginAuth")} />
        <Checkbox name="clientIPAuth" label={t("proxy.auth.ipAuth")} />
      </StyledBlockAuthBody>
    </FormBlock>
  );
};
