import { useId } from "react";

import { useTranslation } from "react-i18next";

import {
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "components/common/New/Table/ToolTable.styled";
import Tooltip from "components/ui/New/Tooltip/Tooltip";

import styles from "../WebRTCLeakTest.module.scss";
import { StyledStatus, StyledStatusWrapper } from "./ResultTable.styled";

export const ResultsTable = ({ data, status }) => {
  const { t } = useTranslation();
  const customTooltipId = useId();

  return (
    <StyledTable padding="0" boxShadow="none" overflow="visible" display="none">
      <TableWrapper>
        <TableHeadRow height="48px">
          <TableHeadCol width="33%" padding="20px 0 20px 24px">
            {t("webRTCLeakTest.table.ip")}
          </TableHeadCol>
          <TableHeadCol width="33%" padding="20px 0 20px 24px">
            {t("webRTCLeakTest.table.type")}
          </TableHeadCol>
          <TableHeadCol width="33%" padding="20px 0 20px 24px">
            {t("webRTCLeakTest.table.status")}
          </TableHeadCol>
        </TableHeadRow>
        <TableBody maxHeight="1086px">
          {data.map((item, index) => (
            <TableRow
              key={index}
              height="64px"
              borderBottom="none"
              hoverBackground="none"
              cursor="default"
            >
              <TableCol width="33%" padding="12px 0 12px 24px">
                <TableText>{item.ipAddress}</TableText>
              </TableCol>
              <TableCol width="33%" padding="12px 0 12px 24px">
                <TableText width="auto">{item.type || "Public IPv4"}</TableText>
              </TableCol>
              <TableCol width="33%" padding="12px 0 12px 24px">
                <StyledStatusWrapper>
                  <StyledStatus statue={status ? "error" : "success"}>
                    {status
                      ? t("webRTCLeakTest.table.leak")
                      : t("webRTCLeakTest.table.noLeak")}
                  </StyledStatus>
                  <Tooltip
                    body={
                      status
                        ? t("webRTCLeakTest.table.leakTooltip")
                        : t("webRTCLeakTest.table.noLeakTooltip")
                    }
                    className={styles.tooltip}
                    id={customTooltipId}
                    place="top"
                  >
                    <img src="img/icons/information.svg" alt="" />
                  </Tooltip>
                </StyledStatusWrapper>
              </TableCol>
            </TableRow>
          ))}
        </TableBody>
      </TableWrapper>
    </StyledTable>
  );
};
