import { useEffect, useState } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Title } from "components/ui/New/Title/Title";

import { AllActions } from "store/reducers/AllActions";
import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "store/reducers/UserReducer/User.selectors";

import { useDispatchedActions } from "hooks";

import {
  StyledContainer,
  StyledContentBlock,
  StyledSiteNotFound,
  StyledTitleContainer
} from "./ServicePage.styled";
import { About } from "./components/About/About";
import { Alternative } from "./components/Alternative/Alternative";
import { Info } from "./components/Info/Info";

const ServicePage = () => {
  // **Props
  const { alias } = useParams();
  const { t } = useTranslation();

  // **Redux state
  const { site, statistic, reviews } = useSelector(getAllProxySite);
  const {
    topSites,
    pageContent: { current }
  } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Local state
  const [isLoading, setIsLoading] = useState(alias !== site.data?.alias);

  // **Dispatch
  const {
    getProxySiteInfo,
    getProxySiteReviewsAmount,
    getTopSites,
    getAllPromocodesBySite,
    getProxySiteReviews,
    getProxySiteCompaints,
    setSiteViewStatistic
  } = useDispatchedActions();

  useEffect(() => {
    if (alias !== site.data?.alias) {
      if (!statistic.isStatisticSites.includes(alias)) {
        setSiteViewStatistic(alias);
      }

      getProxySiteInfo(alias);
      getProxySiteReviewsAmount(alias);
      getAllPromocodesBySite(alias);
      if (!reviews.isDataLoaded) {
        getProxySiteReviews({
          sort: "creationDate",
          siteId: alias,
          page: 0,
          size: 4
        });
      }
      if (isUserAuthenticated) {
        getProxySiteCompaints({
          sort: {
            creationDate: "ASC"
          },
          siteId: alias,
          currentPage: 0,
          size: 10
        });
      }

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  useEffect(() => {
    if (!topSites.isDataLoaded) {
      getTopSites();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topSites.isDataLoaded]);

  return (
    <>
      <section>
        <StyledContainer>
          {!site.isError ? (
            <>
              <StyledTitleContainer>
                <Title tag={"h1"} loading={isLoading}>
                  {current?.data?.h1 || site.data?.name}
                </Title>
              </StyledTitleContainer>
              <StyledContentBlock>
                <Info />
                <About />
                <Alternative />
              </StyledContentBlock>
            </>
          ) : (
            <StyledSiteNotFound>
              {t("proxySitePage.notFound")}
            </StyledSiteNotFound>
          )}
        </StyledContainer>
      </section>
    </>
  );
};

export default ServicePage;

ServicePage.getServerSideState = async (store, params, api, req) => {
  let identifier = "";
  if (params.tag === "proxy-site-promo") {
    [, , identifier] = req._parsedUrl.pathname.split("/").reverse();
  } else {
    [, identifier] = req._parsedUrl.pathname.split("/").reverse();
  }

  const { data: pageContent } = await axios.post(
    `${api}/api/page/front/content`,
    {
      ...params,
      params: {
        ...params.params,
        siteId: identifier
      }
    }
  );

  const { data: topSites } = await axios.get(`${api}/api/front/site/top`);

  const { data: goals } = await axios.get(`${api}/api/goal/front/all`, {
    params: { params: { languageCode: params.languageCode } }
  });
  const { data: goalsByGroups } = await axios.get(
    `${api}/api/front/goal/group/all`,
    { params: { languageCode: params.languageCode } }
  );

  const { data: proxySiteInfo } = await axios.get(
    `${api}/api/front/site/proxy/description`,
    { params: { id: identifier } }
  );
  const { data: reviewsAmount } = await axios.get(
    `${api}/api/front/site/reviews/amount`,
    { params: { siteId: identifier } }
  );
  const { data: rating } = await axios.get(`${api}/api/front/site/rating`, {
    params: { siteId: identifier, step: "days" }
  });
  const { data: promocodes } = await axios.get(
    `${api}/api/admin/promocode/site`,
    { params: { siteId: identifier } }
  );
  const { data: review } = await axios.get(
    `${api}/api/front/site/review/sort`,
    {
      params: {
        sort: "creationDate",
        siteId: identifier,
        page: 0,
        size: 4
      }
    }
  );

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(
    AllActions.setPageContent({
      data: pageContent,
      lang: params.languageCode,
      innerTag: params.innerTag
    })
  );
  store.dispatch(AllActions.setTopSites(topSites));
  store.dispatch(AllActions.setGoals(goals));
  store.dispatch(AllActions.setGoalsByGroups(goalsByGroups));
  store.dispatch(AllActions.setProxySiteInfo(proxySiteInfo));
  store.dispatch(AllActions.setProxySiteReviewsAmount(reviewsAmount));
  store.dispatch(AllActions.setProxySiteRating(rating));
  store.dispatch(AllActions.setAllPromocodesBySite(promocodes));
  store.dispatch(
    AllActions.setProxySiteReviews({
      data: review,
      params: {
        sort: "creationDate",
        siteId: identifier,
        page: 0,
        size: 4
      }
    })
  );
};
