/* eslint-disable no-undef */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { StyledDataTable } from "components/common/New/Table/Table.styled";
import {
  BoxCell,
  HeaderNameCell,
  NoDataComponent,
  SiteNameCell,
  TextCell
} from "components/common/New/TableColumns";
import YesNo from "components/common/New/YesNo/YesNo";
import Rating from "components/ui/New/Raiting/Rating";
import { SkeletonMainPageTable } from "components/ui/New/Skeleton/SkeletonMainPageTable/SkeletonMainPageTable";
import { TextButton } from "components/ui/New/TextButton/TextButton";

import { useLangUrlDefault } from "hooks";

import { time } from "utils/helpers";

export const ProxyTable = ({ isLoading, tableData, withOutPagination }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const [data, setData] = useState(tableData);
  const columns = [
    {
      name: (
        <HeaderNameCell minWidth={285}>
          {t("proxyPage.content.table.name.name")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell minWidth={285}>
          <SiteNameCell
            imgUrl={row?.imageFile}
            name={row?.name}
            rating={row?.rating}
            showAnnotation
            positive={row?.ratingPositive}
            negative={row?.ratingNegative}
            linkToReview={`${hrefLang}/new/proxy/${row.alias}/#reviews`}
          />
        </BoxCell>
      ),
      minWidth: "285px"
    },
    {
      name: (
        <HeaderNameCell minWidth={100} sortable>
          {t("proxyPage.content.table.rating.name")}
        </HeaderNameCell>
      ),

      cell: (row) => (
        <BoxCell minWidth={100}>
          <Rating rating={row?.rating} size={"md"} />
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row?.rating,
      minWidth: "100px"
    },

    {
      name: (
        <HeaderNameCell width={100} sortable>
          {t("proxyPage.content.table.minPrice.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={100}>
          <TextCell>{`$${row?.price}`}</TextCell>
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row.price,
      width: "100px"
    },
    {
      name: (
        <HeaderNameCell width={110} sortable>
          {t("proxyPage.content.table.minRent.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={110}>
          <TextCell>
            {time(
              row?.minRentPeriod?.periodName,
              row?.minRentPeriod?.periodValue,
              queryLang,
              t("times", { returnObjects: true })
            )}
          </TextCell>
        </BoxCell>
      ),
      sortable: true,
      selector: (row) => row?.minRentPeriod?.periodMilliseconds,
      width: "110px"
    },
    {
      name: (
        <HeaderNameCell width={94}>
          {t("proxyPage.content.table.freeTest.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={94}>
          <YesNo value={row?.freeTest} />
        </BoxCell>
      ),

      width: "94px"
    },
    {
      name: (
        <HeaderNameCell width={105}>
          {t("proxyPage.content.table.site.title")}
        </HeaderNameCell>
      ),
      cell: (row) => (
        <BoxCell width={105}>
          <TextButton
            size={"sm"}
            color={"table"}
            iconRight={"arrowAltRight"}
            linkTo={`${hrefLang}/new/proxy/${row.alias}/`}
          >
            {t("proxyPage.content.table.site.btn")}
          </TextButton>
        </BoxCell>
      ),
      width: "105px"
    }
  ];
  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    const block = document.querySelector(".MainPageTable");
    let interval;
    if (block) {
      // TODO: mabe  do this better
      const smoothScrollToBottom = (element) => {
        // Прокрутить вниз только если прокрутка не производится уже
        if (element.scrollTop !== element.scrollHeight - element.clientHeight) {
          const target = element.scrollHeight - element.clientHeight;
          let currentScroll = element.scrollTop;
          const step = Math.abs(currentScroll - target) / 20; // Чем больше значение, тем быстрее скролл
          interval = setInterval(() => {
            if (currentScroll < target) {
              currentScroll += step;
              if (currentScroll >= target) {
                currentScroll = target;
                clearInterval(interval);
              }
            } else {
              currentScroll -= step;
              if (currentScroll <= target) {
                currentScroll = target;
                clearInterval(interval);
              }
            }
            element.scrollTop = currentScroll;
          }, 15); // Интервал в миллисекундах для плавности прокрутки
        }
      };

      smoothScrollToBottom(block);
    }
    return () => {
      clearInterval(interval);
    };
  }, [data]);

  return (
    <StyledDataTable
      className="MainPageTable"
      styleRules={{
        showedAll: withOutPagination
      }}
      columns={columns}
      fixedHeader
      fixedHeaderScrollHeight="724px"
      data={data}
      progressPending={isLoading}
      progressComponent={<SkeletonMainPageTable cells={10} />}
      persistTableHead={!isLoading}
      noDataComponent={<NoDataComponent bordered />}
    />
  );
};
