/* eslint-disable no-console */
import axios from "axios";

import { AllActions } from "../../store/reducers/AllActions";

export const collectDefaultContent = async (store, params, api, req) => {
  // const isProxyPage = isEmpty(params?.params) && params?.tag === "proxy";
  const isBlogInnerPage = params?.tag === "bloginner";
  const newParams = () => {
    // if (isProxyPage) return { fpt: "IPv4" };
    if (isBlogInnerPage) return { articleId: params?.alias };
    return params?.params;
  };
  // chenhe this
  let identifier = "";
  if (params.tag === "proxy-site-promo") {
    [, , identifier] = req._parsedUrl.pathname.split("/").reverse();
  } else {
    [, identifier] = req._parsedUrl.pathname.split("/").reverse();
  }
  // end
  const fetchParams = {
    ...params,
    params: newParams(),
    // location: isProxyPage ? `${params?.location}?fpt=IPv4` : params?.location,
    location: params?.location,
    pathname: isBlogInnerPage ? null : params?.pathname
  };
  // chenhe this
  if (params.tag.includes("proxy-site")) {
    fetchParams.params.siteId = identifier;
  }
  const requests = [
    axios.post(`${api}/api/page/front/content`, fetchParams),
    axios.get(`${api}/api/translation/all`),
    axios.get(`${api}/api/front/site/statistic`),
    axios.get(`${api}/api/front/proxy/type/all`),
    axios.get(`${api}/api/front/all/country`),
    axios.get(`${api}/api/admin/currency/get/all`),
    axios.get(`${api}/api/admin/rent/get/all`),
    axios.get(`${api}/api/front/site/proxy/comparison_widget`),
    axios.get(`${api}/api/front/site/promo/alternate`),
    axios.get(`${api}/api/front/site/reviews/last`),
    axios.get(`${api}/api/front/site/top`),
    axios.get(`${api}/api/front/social`),
    axios.get(`${api}/api/front/captcha`),
    axios.get(`${api}/api/front/property/main/block/list`),
    axios.get(`${api}/api/system/properties`),
    axios.get(`${api}/api/front/site/blog/sidebar`, {
      params: { languageCode: params.languageCode }
    }),
    axios.get(`${api}/api/front/site/status`)
    // axios.get(`${api}/api/front/site/blog`, {
    //   params: {
    //     page: 0,
    //     size: 16,
    //     category: params.params.tab === "articles",
    //     languageCode: params.languageCode
    //   }
    // })
  ];
  // eslint-disable-next-line no-undef
  await Promise.all(requests)
    .then((responses) => {
      const [
        pageContent,
        translation,
        statistic,
        proxyTypes,
        countries,
        currencies,
        rentPeriods,
        comparisonWidgetInfo,
        alternatePromocodes,
        reviews,
        sellers,
        socials,
        captchaConfig,
        mainBlockList,
        seoDataDefault,
        sidebar,
        siteStatuses
        // blogContent
      ] = responses;
      if (pageContent?.data) {
        store.dispatch(
          AllActions.setPageContent({
            data: pageContent.data,
            lang: params.languageCode,
            innerTag: params.innerTag
          })
        );
      }
      if (translation.data) {
        store.dispatch(
          AllActions.setLanguages(
            translation.data.map((lang) => ({
              label: lang.languageNameOriginal,
              value: lang.languageCode,
              id: lang.id
            }))
          )
        );
      }
      if (statistic?.data) {
        store.dispatch(AllActions.setStatistic(statistic.data));
      }
      if (proxyTypes?.data) {
        store.dispatch(AllActions.setProxyTypes(proxyTypes.data));
      }

      if (countries?.data) {
        store.dispatch(AllActions.setCountries(countries.data));
      }

      if (currencies?.data) {
        store.dispatch(AllActions.setCurrencies(currencies.data));
      }

      if (rentPeriods?.data) {
        store.dispatch(AllActions.setRentPeriods(rentPeriods.data));
      }

      if (comparisonWidgetInfo?.data) {
        store.dispatch(
          AllActions.setComparisonWidgetInfo(comparisonWidgetInfo.data)
        );
      }

      if (alternatePromocodes?.data) {
        store.dispatch(
          AllActions.setAlternatePromocodes(alternatePromocodes.data)
        );
      }

      if (reviews?.data) {
        store.dispatch(AllActions.setReviews(reviews.data));
      }

      if (sellers?.data) {
        store.dispatch(AllActions.setSellers(sellers.data));
      }

      if (captchaConfig?.data) {
        store.dispatch(AllActions.setCaptchaConfig(captchaConfig.data));
      }

      if (socials?.data) {
        store.dispatch(AllActions.setSocials(socials.data));
      }

      if (mainBlockList?.data) {
        store.dispatch(AllActions.setMainBlockList(mainBlockList.data));
      }
      if (seoDataDefault?.data) {
        store.dispatch(AllActions.setSeoDataDefault(seoDataDefault.data));
      }
      if (sidebar?.data) {
        store.dispatch(AllActions.setBlogSidebarContent(sidebar.data));
      }
      if (siteStatuses?.data) {
        store.dispatch(AllActions.setCabinetSiteStatuses(siteStatuses.data));
      }
      // if (blogContent?.data) {
      //   store.dispatch(AllActions.setBlogContent(blogContent?.data));
      // }
    })
    .catch(() => {
      console.log("error collecting state!!!");
    });
};
