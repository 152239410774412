import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { GroupButtonTab } from "components/ui/New/GroupButtonTab/GroupButtonTab";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { BaseInfo } from "./BaseInfo/BaseInfo";
import { StyledInfo } from "./Info.styled";
import { Informations } from "./Informations/Informations";
import { Socials } from "./Socials/Socials";

export const Info = () => {
  // **Props
  const { t } = useTranslation();

  // **Redux state
  const { site } = useSelector(getAllProxySite);

  // **Functions
  const currentTabs = (media, contacts) => {
    const newTabs = [];
    if (site?.data?.proxyTypeProps?.length) {
      newTabs.push({
        value: "info",
        label: t("proxySitePage.info.tabs.info")
      });
    }
    if (media?.length) {
      newTabs.push({
        value: "social",
        label: t("proxySitePage.info.tabs.social")
      });
    }
    if (contacts?.length) {
      newTabs.push({
        value: "contacts",
        label: t("proxySitePage.info.tabs.contacts")
      });
    }
    return newTabs;
  };

  const media = site.data?.newSocialNetworks?.filter(
    (it) => it?.code !== "email"
  );
  const contacts = site.data?.newSocialNetworks?.filter(
    (it) => it?.code === "email"
  );
  const tabs = currentTabs(media, contacts);

  const [show, setShow] = useState(tabs?.length ? tabs[0].value : "info");

  useEffect(() => {
    if (tabs?.length && !tabs.find((it) => it.value === show)) {
      setShow(tabs[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <StyledInfo>
      <BaseInfo />
      {tabs?.length && tabs.length > 1 ? (
        <GroupButtonTab
          data={tabs}
          active={show}
          onSelect={setShow}
          variant="site_info"
        />
      ) : null}
      {site?.isDataLoaded && site?.data?.proxyTypeProps?.length ? (
        <Informations active={show === "info"} />
      ) : null}
      {site?.isDataLoaded && media?.length ? (
        <Socials
          active={show === "social"}
          data={media}
          isMedia
          title={t("proxySitePage.info.socialMedia.title")}
        />
      ) : null}
      {site?.isDataLoaded && contacts?.length ? (
        <Socials
          active={show === "contacts"}
          data={contacts}
          title={t("proxySitePage.info.contacts.title")}
        />
      ) : null}
      {!site?.isDataLoaded ? (
        <>
          <Informations.Skeleton active={show === "info"} />
          <Socials.Skeleton active={show === "social"} isMedia />
          <Socials.Skeleton active={show === "contacts"} />
        </>
      ) : null}
    </StyledInfo>
  );
};
