import styled from "@emotion/styled";

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${(p) => p.theme.shadows.card};
`;

export const PaginationBox = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    padding: 0;
    margin-top: 16px;

    @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
      & [class*="itemsOnPage"] {
        display: none !important;
      }
    }
    @media (min-width: 1560px) {
      margin-top: 0;
    }
  }
`;
