/* eslint-disable react/no-unknown-property */
import PT from "prop-types";
import { useSelector } from "react-redux";

import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import Container from "../Container/Container";

import {
  ContentWrapper,
  HeroSection,
  StyledButton,
  StyledSkeletonBoxTitle,
  SubTitle
} from "./Main.styled";
import HighlightedTitle from "./components";

import styles from "./Main.module.scss";

export const Main = ({
  title,
  subtitle,
  subtitleHtml,
  buttonLabel,
  highlightWord,
  images,
  animation,
  button,
  className,
  contentClassName,
  containerClassName,
  imageClassName,
  star,
  handleButtonClick
}) => {
  const { pageContent } = useSelector(getAllContent);

  const heading = title || pageContent.current?.data?.h1;
  const loading = !title && !pageContent.isDataLoaded;
  return (
    <HeroSection className={className}>
      <Container className={`${styles.container} ${containerClassName}`}>
        <ContentWrapper className={contentClassName}>
          {loading ? (
            <StyledSkeletonBoxTitle>
              <StyledSkeleton />
            </StyledSkeletonBoxTitle>
          ) : (
            <HighlightedTitle
              heading={heading}
              highlightWord={highlightWord}
              star={star}
              loading={loading}
            />
          )}

          {subtitle && <SubTitle>{subtitle}</SubTitle>}

          {subtitleHtml && (
            <SubTitle
              dangerouslySetInnerHTML={{
                __html: subtitleHtml
              }}
            />
          )}

          {button && <StyledButton size="lg" onClick={handleButtonClick}>{buttonLabel}</StyledButton>}
        </ContentWrapper>
        <div className={`${styles.image__wrapper} ${imageClassName}`}>
          {animation ? animation : null}
          <picture>
            {images.mobile1x && (
              <source
                srcSet={`${images.mobile1x} 1x ${
                  images.mobile2x ? `, ${images.mobile2x} 2x` : ""
                }`}
              />
            )}

            <img src={images.mobile2x} alt={heading} fetchpriority="high" />
          </picture>
        </div>
      </Container>
    </HeroSection>
  );
};

Main.propTypes = {
  highlightWord: PT.string,
  title: PT.string,
  subtitle: PT.string,
  subtitleHtml: PT.string,
  buttonLabel: PT.string,
  images: PT.shape({}),
  button: PT.bool,
  star: PT.bool,
  className: PT.string,
  imageClassName: PT.string,
  contentClassName: PT.string,
  animation: PT.node,
  containerClassName: PT.string,
  handleButtonClick: PT.func
};

Main.defaultProps = {
  highlightWord: null,
  title: "",
  subtitle: null,
  subtitleHtml: null,
  buttonLabel: "Select service",
  images: {},
  button: true,
  star: false,
  className: "",
  imageClassName: "",
  contentClassName: "",
  animation: null,
  containerClassName: ""
};
