import { useSelector } from "react-redux";

import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { useLangUrlDefault } from "hooks";

import {
  StyledProxyTypes,
  StyledProxyTypesItem,
  StyledProxyTypesList
} from "./ProxyTypes.styled";

export const ProxyTypes = ({ data, loading }) => {
  const [queryLang] = useLangUrlDefault();
  const { proxyTypes } = useSelector(getAllContent);

  if (!loading && !data.length) {
    return null;
  }

  const currentProxyTypes = proxyTypes?.data?.[queryLang];

  const currentData = loading ? Array.from({ length: 6 }, (_, i) => i) : data;

  return (
    <StyledProxyTypes>
      <StyledProxyTypesList>
        {currentData.map((item) => (
          <StyledProxyTypesItem key={`key-${item}`}>
            {!loading ? (
              currentProxyTypes?.find(({ type }) => type === item)?.name
            ) : (
              <StyledSkeleton width={100} height={14} />
            )}
          </StyledProxyTypesItem>
        ))}
      </StyledProxyTypesList>
    </StyledProxyTypes>
  );
};
