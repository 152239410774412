import styled from "@emotion/styled";

import Button from "components/ui/New/Button/Button";
import { Title } from "components/ui/New/Title/Title";

export const StyledTitleBlock = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 36px;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
    padding: 5px 0;
    width: fit-content;
  }
`;

export const StyledCreateButton = styled(Button)`
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-left: auto;
    width: auto;
  }
`;
