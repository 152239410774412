import { useState } from "react";

import { useTranslation } from "react-i18next";

import Container from "components/common/New/Container/Container";
import { SectionSearch } from "components/common/New/SectionSearch/SectionSearch";

import { mockTableData } from "utils/constants/mockTableData";

import { AttributesTable } from "./AttributesTable/AttributesTable";

import styles from "./Fingerprint.module.scss";

export const Fingerprint = () => {
  const { t } = useTranslation();

  const [http, setHttp] = useState("");
  const [js, setJs] = useState("");

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <SectionSearch
          title={t("fingerprint.httpAttributes")}
          placeholder={t("fingerprint.searchAttributes")}
          value={http}
          onChange={(value) => setHttp(value)}
        />
        <div id="httpAttributes" />
        <AttributesTable data={mockTableData} className={styles.table} />
        <SectionSearch
          title={t("fingerprint.javaScriptAttributes")}
          placeholder={t("fingerprint.searchAttributes")}
          value={js}
          onChange={(value) => setJs(value)}
        />
        <AttributesTable data={mockTableData} />
      </Container>
    </section>
  );
};
