import { useEffect, useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Input } from "components/forms/New";
import LabelCountry from "components/ui/New/LabelContry/LabelCountry";
import Select from "components/ui/New/Select/Select";

import { useLangUrlDefault } from "hooks";

import FormBlock from "../../../components/FormBlock/FormBlock";

import { StyledBoxHidden, StyledSelectBox } from "./BlockDescriptions.styled";

export const BlockDescriptions = () => {
  const { t } = useTranslation();

  const [queryLang] = useLangUrlDefault();
  const [lang, setLang] = useState(queryLang);
  const { errors } = useFormState();
  const { getValues } = useFormContext();
  const options = [
    { value: "en", label: <LabelCountry code="en">English</LabelCountry> },
    { value: "ru", label: <LabelCountry code="ru">Russian</LabelCountry> },
    { value: "ua", label: <LabelCountry code="ua">Ukrainian</LabelCountry> }
  ];

  useEffect(() => {
    const { description } = getValues();
    if (errors?.description?.type === "description-test") {
      const lang = options.find(
        (option) => !description?.[option.value]
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.description?.type === "description-min") {
      const lang = options.find(
        (option) => description[option.value].length < 50
      )?.value;
      if (lang) {
        setLang(lang);
      }
      return;
    }
    if (errors?.description?.type === "description-max") {
      const lang = options.find(
        (option) => description[option.value].length > 100
      )?.value;
      if (lang) {
        setLang(lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  return (
    <FormBlock
      title={t("dashboard.promocodes.addEdit.blocks.description.title")}
      subtitle={t("dashboard.promocodes.addEdit.blocks.description.subtitle")}
    >
      <StyledSelectBox>
        <Select
          name={"lang"}
          value={queryLang}
          renderValue={() =>
            options.find((option) => option.value === lang)?.label
          }
          onChange={setLang}
          options={options}
        />
      </StyledSelectBox>
      {options.map((option) => (
        <StyledBoxHidden key={option.value} hidden={lang !== option.value}>
          <Input
            name={`description.${option.value}`}
            label={t("promocodes.description.label")}
            type={"textarea"}
            showError
            showMaxLenght={100}
            showParentError
            placeholder={t("promocodes.description.placeholder")}
            parentErrorPath={"description"}
          />
        </StyledBoxHidden>
      ))}
    </FormBlock>
  );
};
