import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import YesNo from "components/common/New/YesNo/YesNo";
import SelectNew from "components/ui/New/Select/SelectNew";
import { StyledSkeleton } from "components/ui/New/Skeleton/components/SkeletonTableComponents.styled";

import { getAllProxySite } from "store/reducers/ProxySiteReducer/ProxySite.selectors";

import { useLangUrlDefault } from "hooks";

import { time } from "utils/helpers";

import { InfoItemContainer, InfoItemTitle } from "../Info.styled";

import {
  InformationsContentItem,
  InformationsContentItemValue,
  InformationsContentList
} from "./Informations.styled";

export const Informations = ({ active }) => {
  const [queryLang] = useLangUrlDefault();
  // **Redux state
  const { t } = useTranslation();
  const {
    site: { data }
  } = useSelector(getAllProxySite);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState("");
  const [selectedData, setSelectedData] = useState(null);

  const handlerSelect = (value) => {
    setSelected(value);
    const newSelectedData = data?.proxyTypeProps.find(
      ({ proxyTypeId }) => proxyTypeId === value
    );
    setSelectedData(newSelectedData);
  };

  useEffect(() => {
    const options = data?.proxyTypeProps.map(
      ({ proxyTypeLocalization, proxyTypeId }) => ({
        label: proxyTypeLocalization[queryLang],
        value: proxyTypeId
      })
    );
    if (!selected) {
      setSelected(options[0]?.value);
      setSelectedData(data?.proxyTypeProps[0]);
    }
    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, data]);
  return options?.length && selected ? (
    <InfoItemContainer active={active}>
      <InfoItemTitle>
        {t("proxySitePage.info.informations.title")}
      </InfoItemTitle>
      <SelectNew
        id="informations"
        options={options}
        controlStyle={{ minHeight: "48px" }}
        // defaultValue={selected}
        value={options.find((option) => option.value === selected)}
        onChange={(data) => handlerSelect(data?.value)}
      />
      <InformationsContentList>
        {selectedData?.minRentPeriod?.periodValue ? (
          <InformationsContentItem>
            <span>{t("proxySitePage.info.informations.minRent")}:</span>
            <InformationsContentItemValue>
              {time(
                selectedData?.minRentPeriod?.periodName,
                selectedData?.minRentPeriod?.periodValue,
                queryLang,
                t("times", { returnObjects: true })
              )}
            </InformationsContentItemValue>
          </InformationsContentItem>
        ) : null}
        {selectedData?.minPrice ? (
          <InformationsContentItem>
            <span>{t("proxySitePage.info.informations.minPrice")}:</span>
            <InformationsContentItemValue>
              ${selectedData?.minPrice}
            </InformationsContentItemValue>
          </InformationsContentItem>
        ) : null}
        {selectedData?.proxyType === "ResidentialProxy" ? (
          <>
            <InformationsContentItem>
              <span>{t("proxySitePage.info.informations.pullIP")}:</span>
              <InformationsContentItemValue>
                {selectedData?.pullIp}
              </InformationsContentItemValue>
            </InformationsContentItem>
            <InformationsContentItem>
              <span>{t("proxySitePage.info.informations.minGB")}:</span>
              <InformationsContentItemValue>
                {selectedData?.minBytes
                  ? Number(selectedData?.minBytes) / 1073741824
                  : null}
              </InformationsContentItemValue>
            </InformationsContentItem>
          </>
        ) : null}

        <InformationsContentItem>
          <span>{t("proxySitePage.info.informations.freeTest")}:</span>
          <InformationsContentItemValue>
            <YesNo value={data?.freeTest} />
          </InformationsContentItemValue>
        </InformationsContentItem>
        <InformationsContentItem>
          <span>{t("proxySitePage.info.informations.affiliate")}:</span>
          <InformationsContentItemValue>
            <YesNo value={data?.affiliateProgram} />
          </InformationsContentItemValue>
        </InformationsContentItem>
      </InformationsContentList>
    </InfoItemContainer>
  ) : null;
};

Informations.Skeleton = ({ active }) => (
  <InfoItemContainer active={active}>
    <InfoItemTitle>
      <StyledSkeleton width={120} height={20} />
    </InfoItemTitle>
    <StyledSkeleton height={48} />
    <InformationsContentList>
      {[...Array(5)].map((_, index) => (
        <InformationsContentItem key={index}>
          <StyledSkeleton width={120} />
          <InformationsContentItemValue>
            <StyledSkeleton width={70} />
          </InformationsContentItemValue>
        </InformationsContentItem>
      ))}
    </InformationsContentList>
  </InfoItemContainer>
);
