import IconsSVG from "../../../../../components/ui/New/Icon/IconsSVG";

import {
  StyledCardBody,
  StyledCardClose,
  StyledCardInfo,
  StyledCardTitle
} from "./CardInfo.styled";

export const CardInfo = ({ title, children, onClose, noMargin }) => (
  <StyledCardInfo noMargin={noMargin}>
    {typeof onClose === "function" ? (
      <StyledCardClose onClick={onClose} type="button">
        <IconsSVG name="closeModal" />
      </StyledCardClose>
    ) : null}
    {title && <StyledCardTitle>{title}</StyledCardTitle>}
    <StyledCardBody>{children}</StyledCardBody>
  </StyledCardInfo>
);
