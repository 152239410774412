import BlogInnerPage from "pages/New/BlogInnerPage/BlogInnerPage";
import BlogPage from "pages/New/BlogPage/BlogPage";
import MainPage from "pages/New/MainPage/MainPage";
import ProxyPage from "pages/New/ProxyPage/ProxyPage";
import ServicePage from "pages/New/ServicePage/ServicePage";

export const AppRoutes = [
  {
    path: "/:lang?/new/",
    tag: "main",
    type: "app",
    component: () => import("pages/New/MainPage/MainPage"),
    element: MainPage,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/blog",
    tag: "blog",
    type: "app",
    component: () => import("pages/New/BlogPage/BlogPage"),
    element: BlogPage,
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/blog/:alias",
    tag: "bloginner",
    type: "app",
    component: () => import("pages/New/BlogInnerPage/BlogInnerPage"),
    element: BlogInnerPage,
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/proxy",
    tag: "proxy",
    type: "app",
    component: () => import("pages/New/ProxyPage/ProxyPage"),
    element: ProxyPage,
    isAuthRequired: false,
    isSsr: true
  },

  {
    path: "/:lang?/new/proxy/:alias",
    tag: "proxy-site",
    type: "app",
    component: () => import("pages/New/ServicePage/ServicePage"),
    element: ServicePage,
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  },
  {
    path: "/:lang?/new/proxy/:alias/promocodes",
    tag: "proxy-site-promo",
    type: "app",
    component: () => import("pages/New/ServicePage/ServicePage"),
    element: ServicePage,
    isHeadLight: true,
    isAuthRequired: false,
    isSsr: true
  }
];
