import { useEffect, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Rive } from "components/ui/New/Rive/Rive";

import { getAllContent } from "store/reducers/ContentReducer/Content.selectors";

import { ApiService } from "services";

import {
  useDispatchedActions,
  useLangUrlDefault,
  useMatchedRoute
} from "hooks";

import { animationFaq, animationFaqTools } from "assets/animations";

import {
  StyledAnimationWrapper,
  StyledContainer,
  StyledContent,
  StyledDescription,
  StyledHeading,
  StyledImage,
  StyledList,
  StyledSection
} from "./FAQ.styled";
import FAQCard from "./FAQCard";

const FAQ = ({
  heading,
  description,
  list,
  image,
  image2x,
  tools,
  className
}) => {
  const { t } = useTranslation();
  const [activeElement, setActiveElement] = useState(0);
  const { faq } = useSelector(getAllContent);

  const [queryLang] = useLangUrlDefault();
  const route = useMatchedRoute();
  const { setFaqContent } = useDispatchedActions();

  const handleOpen = (index) => {
    setActiveElement(index);
  };
  const handleClose = () => {
    setActiveElement(null);
  };
  const collapsibleListOfQuestions = (list) =>
    list.map((el, index) => (
      <FAQCard
        handleOpen={
          activeElement === index
            ? () => handleClose()
            : () => handleOpen(index)
        }
        isOpened={activeElement === index}
        key={index}
        title={el.question}
      >
        {typeof el?.answer === "string" ? (
          <div
            itemProp="text"
            dangerouslySetInnerHTML={{ __html: el.answer }}
          />
        ) : (
          el.answer.map((item) => (
            <div itemProp="text" key={item}>
              {item}
            </div>
          ))
        )}
      </FAQCard>
    ));

  useEffect(() => {
    if (!faq.data?.[route?.tag]?.[queryLang]) {
      ApiService.getFaqContent({
        pageTag: route?.tag,
        languageCode: queryLang,
        params: {}
      })
        .then((response) => {
          if (response && response.status !== 200) {
            throw response;
          }
          setFaqContent({
            data: response.data,
            pageTag: route.tag,
            languageCode: queryLang
          });
        })
        .catch(() => {
          toast.error(t("notifications.apiError"));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route?.tag, queryLang]);

  return (
    <StyledSection className={className}>
      <StyledContainer>
        <StyledContent>
          <StyledHeading>{heading || t("faq.heading")}</StyledHeading>
          <StyledDescription
            dangerouslySetInnerHTML={{
              __html: description || t("faq.description")
            }}
          />
          <StyledAnimationWrapper tools={tools}>
            <Rive
              src={tools ? animationFaqTools : animationFaq}
              autoPlay
              top={tools ? -55 : -67}
              right={tools ? -69 : -97}
              bottom={tools ? -83 : -67}
              left={tools ? -69 : -97}
            />
          </StyledAnimationWrapper>
        </StyledContent>
        <StyledList itemScope itemType="https://schema.org/FAQPage">
          {faq.data?.[route?.tag]?.[queryLang]?.length > 0
            ? collapsibleListOfQuestions(faq.data?.[route?.tag]?.[queryLang])
            : collapsibleListOfQuestions(list)}
        </StyledList>
        <StyledImage>
          <source srcSet={`${image} 1x, ${image2x} 2x`} />
          <img
            src={image}
            alt="How we can help you?"
            width="100%"
            loading="lazy"
          />
        </StyledImage>
      </StyledContainer>
    </StyledSection>
  );
};
FAQ.propTypes = {
  description: PT.string,
  heading: PT.string,
  image: PT.node,
  list: PT.arrayOf(
    PT.shape({
      answer: PT.oneOfType([PT.string, PT.arrayOf(PT.string)]),
      question: PT.string
    })
  ),
  tools: PT.bool
};
FAQ.defaultProps = {
  image: null,
  list: [],
  animation: null,
  tools: false
};

export default FAQ;
