import styles from "../../Uploader.module.scss";

const Img = ({ croppedFileObjects, defaultImage }) => (
  <img
    className={styles.uploader__image}
    src={
      typeof croppedFileObjects[0] === "object"
        ? URL?.createObjectURL(croppedFileObjects[0])
        : defaultImage
    }
    loading="lazy"
    alt=""
    aria-hidden="true"
  />
);

export default Img;
